module.exports = {
  csvFields: {
    fields: [
      'status',
      'lpGroup',
      'name',
      'totalAllFund',
      'subscription',
      'capitalCommitted',
      'capitalCommittedFund2',
      'capitalCommittedFund3',
      'investorType',
      'contactPerson',
      'contactEmail',
      'contactPersonPhone',
      'received',
      'signed',
      'closing',
      'location',
      'wireInfo',
      'naturalPerson',
      'usPersonSecAct',
      'usPersonIrc',
      'finra',
      'foia_a',
      'foia_b',
      'foia_c',
      'foia_d',
      'foia_e',
      'foia_f',
      'badActor',
      'cfiusForeignPerson',
      'foreignGovtControlled',
      'accreditedInvestor',
      'qualifiedPurchaser',
      'qualifiedClient',
      'fatfCountry',
      'country',
      'bankLocation',
      'wiringBankCustomer',
      'erisa_a',
      'erisa_b',
      'plannedAssetsPerc',
      'look_a',
      'look_b',
      'look_c',
      'look_d',
      'look_e',
      'governmentEntity',
      'bhcaPartner',
      'foundationPartner',
      'publicPensionPartner',
      'fundOfFunds',
      'taxExempt',
      'taxedAsPartnership',
      'usBeneficialOwners',
      'taxExemptOwners',
      'restrictedPersonStatus',
      'coveredInvestorStatus',
      'determinationExemptedEntity',
      'exemptedEntityStatus',
      'escrow',
      'reportsPrimaryContactName',
      'reportsPrimaryContactEmail',
      'reportsPrimaryContactPhone',
      'reportsSecondaryContactName',
      'reportsSecondaryContactEmail',
      'reportsSecondaryContactPhone',
      'partnerStatementsPrimaryContactName',
      'partnerStatementsPrimaryContactEmail',
      'partnerStatementsPrimaryContactPhone',
      'partnerStatementsSecondaryContactName',
      'partnerStatementsSecondaryContactEmail',
      'partnerStatementsSecondaryContactPhone',
      'taxPrimaryContactName',
      'taxPrimaryContactEmail',
      'taxPrimaryContactPhone',
      'taxSecondaryContactName',
      'taxSecondaryContactEmail',
      'taxSecondaryContactPhone',
      'capitalPrimaryContactName',
      'capitalPrimaryContactEmail',
      'capitalPrimaryContactPhone',
      'capitalSecondaryContactName',
      'capitalSecondaryContactEmail',
      'capitalSecondaryContactPhone',
      'stockPrimaryContactName',
      'stockPrimaryContactEmail',
      'stockPrimaryContactPhone',
      'stockSecondaryContactName',
      'stockSecondaryContactEmail',
      'stockSecondaryContactPhone',
      'legalPrimaryContactName',
      'legalPrimaryContactEmail',
      'legalPrimaryContactPhone',
      'legalSecondaryContactName',
      'legalSecondaryContactEmail',
      'legalSecondaryContactPhone',
      'bankName',
      'acctName',
      'swiftCode',
      'acctNumber',
      'bankaba',
      'furtherCredit',
      'institutionalAccountStatus',
      'foresite_investor_type',
      'foresiteFinraAffiliation',
      'foresiteFinraAffiliationFurtherDetails',
      'foresitePublicCompanyAffiliation',
      'foresitePublicCompanyAffiliationFurtherDetails',
      'foresiteEntities',
      'foresiteEntitiesFurtherDetails',
      'hcm',
      'rule5130_finraNewIssues_1',
      'rule5130_finraNewIssues_2',
      'rule5131_finraNewIssues_1',
      'rule5131_finraNewIssues_2',
      'contactPersonAddress',
      'notes',
      'PartAFinra',
      'PartBFinra',
      'beneficialOwnershipAdvisers',
      'retirementPlan',
      'foreignPartner',
      'Rep7EUandUK',
      'Rep8Switzerland',
      'jurisdictionOrganization',
    ],
    fieldNames: [
      'Status',
      'LP Group',
      'Name',
      'Capital Committed',
      'Returning Investor',
      'Investor Type',
      'Contact Person',
      'Email',
      'Phone',
      'Date Received',
      'Signed Date',
      'Closing Date',
      'Place of Residence/Business',
      'Wire Info',
      'Natural Person',
      'US Person (SEC Act)',
      'US Person (IRC)',
      'FINRA',
      'FOIA(a)',
      'FOIA(b)',
      'FOIA(c)',
      'FOIA(d)',
      'FOIA(e)',
      'FOIA(f)',
      'Bad Actor',
      'Accredited Investor',
      'Qualified Purchaser',
      'Qualified Client',
      'FATF Country',
      'If no, Country',
      'Customer of Wiring Bank',
      'ERISA(a)',
      'ERISA(b)',
      '% of Plan Assets',
      'Lookthrough (a)',
      'Lookthrough (b)',
      'Lookthrough (c)',
      'Lookthrough (d)',
      'Lookthrough (e)',
      'Government Entity',
      'BHCA Partner',
      'Foundation Partner',
      'Public Pension Partner',
      'Fund Of Funds',
      'Tax Exempt',
      'Taxed as a Partnership',
      '100% U.S. Beneficial Owners',
      'Tax Exempt Beneficial Owners',
      'Escrow',
      'Quarterly and Annual Reports Primary Contact Name',
      'Email Address',
      'Phone Number',
      'Quarterly and Annual Reports Secondary Contact Name',
      'Email Address',
      'Phone Number',
      `Quarterly Partner's Capital Statements Second Contact Name`,
      'Email Address',
      'Phone Number',
      `Quarterly Partner's Capital Statements Third Contact Name`,
      'Email Address',
      'Phone Number',
      'Tax Information/K-1s Primary Contact Name',
      'Email Address',
      'Phone Number',
      'Tax Information/K-1s Secondary Contact Name',
      'Email Address',
      'Phone Number',
      'Capital Call Notices Primary Contact Name',
      'Email Address',
      'Phone Number',
      'Capital Call Notices Secondary Contact Name',
      'Email Address',
      'Phone Number',
      'Cash and Stock Distributions Primary Contact Name',
      'Email Address',
      'Phone Number',
      'Cash and Stock Distributions Secondary Contact Name',
      'Email Address',
      'Phone Number',
      'Legal Documents Primary Contact Name',
      'Email Address',
      'Phone Number',
      'Legal Documents Secondary Contact Name',
      'Email Address',
      'Phone Number',
      'Bank Name',
      'Accountholder Name',
      'Swift Code',
      'Account Number',
      'Bank ABA#',
      'For Further Credit to',
      'City/State/Country',
      'CFIUS Foreign Person',
      'Foreign Govt Controlled',
      'Institutional Account Status',
      'Foresite Investor Type',
      'Foresite FINRA Affiliation',
      'Foresite FINRA Affiliation Further Details',
      'Foresite Public Company Affiliation',
      'Foresite Public Company Affiliation Further Details',
      'Foresite Entities',
      'Foresite Entities Further Details',
      'HCM',
      'rule5130 Finra NewIssues 1',
      'rule5130 Finra New Issues 2',
      'rule5131 Finra New Issues 1',
      'rule5131 Finra New Issues 2',
      'Full Mailing Address',
      'Capital Committed Fund 2',
      'Capital Committed Fund 3',
      'Total All Funds',
      'Beneficial Ownership for Advisers?',
      'Notes',
    ],
  },
  filterStatus: (target) => {
    switch (target) {
      case 'Status':
        return '0';
      case 'LP Group':
        return '1';
      case 'Name':
        return '2';
      case 'Login Email':
        return '3';
      case 'Capital Committed':
        return '4';
      case 'Investor type':
        return '5';
      case 'Contact Person':
        return '6';
      case 'Email':
        return '7';
      case 'Phone':
        return '8';
      case 'Full Mailing Address':
        return '9';
      case 'Received':
        return '10';
      case 'Signed':
        return '11';
      case 'Closing Date':
       return '12';
      case 'Place of Residence/Business':
        return '13';
      case 'Wire Info?':
        return '14';
      case 'Natural Person':
        return '15';
      case 'US Person (SEC Act)?':
        return '16';
      case 'US Person (IRC)?':
        return '17';
      case 'FINRA':
        return '18';
      case 'FOIA(a)':
        return '19';
      case 'FOIA(b)':
        return '20';
      case 'FOIA(c)':
        return '21';
      case 'FOIA(d)':
        return '22';
      case 'FOIA(e)':
        return '23';
      case 'FOIA(f)':
        return '24';
      case 'Bad Actor?':
        return '25';
      case 'Accredited Investor?':
        return '26';
      case 'Qualified Purchaser?':
        return '27';
      case 'Qualified Client?':
        return '28';
      case 'FATF Country':
        return '29';
      case 'If no, Country?':
        return '30';
      case 'Customer of Wiring Bank?':
        return '31';
      case 'ERISA(a)':
        return '32';
      case 'ERISA(b)':
        return '33';
      case '% of Plan Assets':
        return '34';
      case 'Lookthrough (a)':
        return '35';
      case 'Lookthrough (b)':
        return '36';
      case 'Lookthrough (c)':
        return '37';
      case 'Lookthrough (d)':
        return '38';
      case 'Lookthrough (e)':
        return '39';
      case 'Government Entity?':
        return '40';
      case 'BHCA Partner?':
        return '41';
      case 'Foundation Partner?':
        return '42';
      case 'Public Pension Partner?':
        return '43';
      case 'Fund-of-Funds?':
        return '44';
      case 'Tax Exempt':
        return '45';
      case 'Taxed as a Partnership':
        return '46';
      case '100% U.S. Beneficial Owners':
        return '47';
      case 'Tax Exempt Beneficial Owners':
        return '48';
      case 'Escrow':
        return '49';
      case 'Exceptions':
        return '50';
      case 'Notes':
        return '51';
      case 'Quarterly and Annual Reports Primary Contact Name':
        return '52';
      case 'Quarterly and Annual Reports Secondary Contact Name':
        return '54';
      case 'Tax Information/K-1s Primary Contact Name':
        return '57';
      case 'Tax Information/K-1s Secondary Contact Name':
        return '60';
      case 'Capital Call Notices Primary Contact Name':
        return '63';
      case 'Capital Call Notices Secondary Contact Name':
        return '66';
      case 'Cash and Stock Distributions Primary Contact Name':
        return '69';
      case 'Cash and Stock Distributions Secondary Contact Name':
        return '72';
      case 'Legal Documents Primary Contact Name':
        return '75';
      case 'Legal Documents Secondary Contact Name':
        return '78';
      case 'Bank Name':
        return '81';
      case 'Accountholder Name':
        return '82';
      case 'Swift Code':
        return '83';
      case 'Account Number':
        return '84';
      case 'Bank ABA#':
        return '85';
      case 'For Further Credit to':
        return '86';
      case 'City/State/Country':
        return '87';
      case 'CFIUS Foreign Person':
        return '88';
      case 'Foreign Govt Controlled':
        return '89';
      case 'Institutional Account Status':
        return '90';
      case 'Foresite Investor Type':
        return '91';
      case 'Foresite FINRA Affiliation':
        return '92';
      case 'Foresite FINRA Affiliation Further Details':
        return '93';
      case 'Foresite Public Company Affiliation':
        return '94';
      case 'Foresite Public Company Affiliation Further Details':
        return '95';
      case 'Foresite Entities':
        return '96';
      case 'Foresite Entities Further Details':
        return '97';
      case 'Creation Date':
        return '98';
      case 'HCM':
        return '99';
      case 'Capital Committed Fund 2':
        return '100';
      case 'Capital Committed Fund 3':
        return '101';
      case 'Total All Funds':
         return '102';
      default:
        return'-1';
    }
  },
};
