// Receive the props of the component using it
import axios from 'axios';
import * as _ from 'lodash';
import * as iqID from '../../utils/constants/questionnaire/identifiers';
import {
  LimitedPartnerV1,
  LimitedPartnerV2,
  LimitedPartnerV3,
  LimitedPartnerV4,
  LimitedPartnerV5,
  LimitedPartnerV6,
  LimitedPartnerV7,
  LimitedPartnerV8,
  LimitedPartnerV8_1,
  LimitedPartnerV9,
  LimitedPartnerV10,
  LimitedPartnerV11,
  LimitedPartnerV12,
  LimitedLiabilityCompanyV1,
  LimitedLiabilityCompanyV2,
  LimitedLiabilityCompanyV3,
  LimitedLiabilityCompanyV4,
  LimitedLiabilityCompanyV5,
  LimitedLiabilityCompanyV6,
  LimitedLiabilityCompanyV6_1,
  LimitedLiabilityCompanyV7,
  LimitedLiabilityCompanyV8,
  LimitedLiabilityCompanyV9,
  LimitedLiabilityCompanyV10,
  ForesiteV1,
  ForesiteV2,
  ForesiteV3,
  ForesiteV4,
  ForesiteV5,
  ForesiteV6,
  ForesiteV7,
  ForesiteV8,
  QuestaCapitalV1,
  QuestaCapitalV2,
  QuestaCapitalV3,
  QuestaCapitalV4,
  QuestaCapitalV5,
  QuestaCapitalV6,
  QuestaCapitalV7,
  QuestaCapitalV8,
  InitializedV1,
  InitializedV2,
  InitializedV3,
  InitializedV4,
  InitializedV5,
  InitializedV6,
  InitializedV7,
  InitializedV8,
  HCMV1,
  HCMV2,
  HCMV3,
  HCMV4,
  HCMV5,
  ForesiteLabsV1,
  ForesiteLabsV2,
  ForesiteLabsV3,
  ForesiteLabsV4,
  ForesiteLabsV5,
  LPABBRV1,
  LPABBRV1_1,
  LPABBRV2,
  LPABBRV3,
  LPABBRV4,
  LPABBRV5,
  OSAGEV1,
  OSAGEV2,
  OSAGEV3,
  NewLimitedPartnerV1,
  NewLimitedPartnerV2,
  NewLimitedPartnerV3,
  FireworkV1,
  FireworkV2,
  FireworkV3,
  PrometheanV1,
  PrometheanV2,
  PrometheanV3,
  AccelV1,
  AccelV2,
  AccelV3,
  AccelV4,
  AccelMainV1,
  AccelMainV2,
  AccelEntrepreneursV1,
  AccelEntrepreneursV2,
  AccelEtpnReturningV1,
  AccelEtpnReturningV2,
  AccelEtpnReturningMultiV1,
  LPMULTIFUNDV1,
  LPMULTIFUNDV2,
  LPMULTIFUNDV3,
  LPMULTIFUNDV4,
  TcgCrossOverV1,
  TcgCrossOverV2,
  TcgCrossOverV3,
  IOLARV1,
  IOLARV2,
  IOLARV3,
  BITKRAFTV1,
  BITKRAFTV2,
  BITKRAFTV3,
  ElephantPartnerV1,
  ElephantPartnerV2,
  ElephantPartnerV3,
  LimitedPartnershipMFV1,
  LimitedPartnershipMFV2,
  LimitedPartnershipMFV3,
  LimitedPartnershipMFV4,
  AudereV1,
  AudereV2,
  AudereV3,
  CaymanV1,
  CaymanV2,
  CaymanV3,
  CaymanV4,
  SROneV1,
  SROneV2,
  FrazierV1,
  FrazierV2,
} from '../../utils/constants/questionnaire/versions';
import { SERVICE_CONFIG_QUESTIONNAIRE } from '../../utils/service-commons';
import { API_URL } from '../../utils/service-commons';
import { getInvestorFundQuestions } from '../../utils/iq.questions.array';
import { STATUS_TYPES } from '../../utils/constants/status';
import DateTimeZone from '../../utils/date-time-zone';
import { FUND_VERSION } from '../../utils/constants/fund-versions';

const submitQuestionnaire =(props) => {
  const {
    questionnaireContext,
    questionnaireContext: { investorFund },
  } = props;
  const payload = { status: true };
  if (!questionnaireContext.multiAccess) {
    payload.investorFund = investorFund;
  } else {
    payload.limitedPartnerFund = investorFund;
  }
  payload.submitTimestamp = new DateTimeZone();
  return axios.post(
    `${API_URL()}/investorFund/submit`,
    payload,
    SERVICE_CONFIG_QUESTIONNAIRE()
  );
};

 const requireTCGQuestions = [
    'id_investor_status_7_tcg_a',
    'id_investor_status_7_tcg_b',
    'id_investor_status_7_tcg_c',
    'id_investor_status_7_tcg_d',
    'id_investor_status_7_tcg_e',
    'id_investor_status_7_tcg_f',
    'id_investor_status_7_tcg_g',
    'id_investor_status_7_tcg_h',
    'id_investor_status_7_tcg_i',
    'id_investor_status_7_tcg_j',
    'id_investor_status_7_tcg_k',
    'id_investor_status_7_tcg_l',
    'id_investor_status_7_tcg_m',
    'id_investor_status_7_tcg_n',
    'id_investor_status_7_tcg_o',
  ];

export const confirmReturningInvestorCanSign = (props) => {
  const { questionnaireContext } = props;
  const parts = []
  const missingField = [];
  const { includesFormPFQuestion } = questionnaireContext.fund;
   const currentIQVersion = questionnaireContext.investorFund && 
    questionnaireContext.investorFund.questionnaire &&
    questionnaireContext.investorFund.questionnaire.iqVersion;
  const generalInfo = JSON.parse(sessionStorage.getItem('generalInfo'));
  const requireCapitalCommitment = questionnaireContext.investorFund &&  questionnaireContext.investorFund.fund &&
    questionnaireContext.investorFund.fund.requireCapitalCommitment === "no" ? false : true;
  if ((generalInfo && generalInfo.name === '') || (generalInfo && !generalInfo.name) || !generalInfo) {
    missingField.push('Name of Limited Partner');
  }

 if (generalInfo && currentIQVersion && !currentIQVersion.includes(AccelEtpnReturningMultiV1)) {
      const isValidCapitalCommitment =
          !generalInfo.capitalCommitment ||
          generalInfo.capitalCommitment === '' ||
					generalInfo.capitalCommitment === '0' ||
					generalInfo.capitalCommitment === '$0.00' ||
          generalInfo.capitalCommitment === '€0.00';

				const isValidCapitalCommitment2 =
          !generalInfo.capitalCommitmentFund2 ||
          generalInfo.capitalCommitmentFund2 === '' ||
					generalInfo.capitalCommitmentFund2 === '0' ||
					generalInfo.capitalCommitmentFund2 === '$0.00' ||
          generalInfo.capitalCommitment === '€0.00';

				const isValidCapitalCommitment3 =
          !generalInfo.capitalCommitmentFund3 ||
          generalInfo.capitalCommitmentFund3 === '' ||
					generalInfo.capitalCommitmentFund3 === '0' ||
					generalInfo.capitalCommitmentFund3 === '$0.00' ||
          generalInfo.capitalCommitment === '€0.00';

      if ((isValidCapitalCommitment && isValidCapitalCommitment2 && isValidCapitalCommitment3)) {
        if (requireCapitalCommitment) {
          missingField.push('Capital Commitment');
        }
      }
      
  } else if (currentIQVersion && !currentIQVersion.includes(AccelEtpnReturningMultiV1)) {
		missingField.push('Capital Commitment');
	} else if (!currentIQVersion) {
    missingField.push('Capital Commitment');
  }

  if (includesFormPFQuestion) {
    let found = false;
      requireTCGQuestions.forEach((key) => {
        questionnaireContext.investorFund.questionnaire.questions.forEach(
          (question) => {
            if (
              question.descriptionIdentifier === key &&
              question.answer !== 'No' &&
              !found
            ) {
            switch(question.descriptionIdentifier) {
                case 'id_investor_status_7_tcg_o':
                  found = question.description !== "";
                  break;
                case 'id_investor_status_7_tcg_m':
                  found = question.description !== "";
                  break;
                default:
                  found = true
                  break;
              }
            }
          })});
      if (!found) {
        missingField.push('Beneficial Ownership for Advisers');
      }
  };
  if (missingField && missingField.length > 0) {
      parts.push(`Part 1: ${missingField.join(' - ')}`);
      props.setValidationRequiredParts(parts);
      props.isSignaturesEnabled(false);
    } else {
       props.setValidationRequiredParts([]);
;     props.isSignaturesEnabled(true);
    }
};

// const fetchCapitalCommitmentValidation = (props,func) => { // TODO: fix function - not get call on investor users
//   const { questionnaireContext, getFundById } = props;
//   const fundID =  questionnaireContext.fund._id
//   if (!fundID) {
//     return null
//   }
//   return getFundById(fundID, (fetchedFund) => func(fetchedFund))
// }

export const confirmDocCanBeSigned = async (props) => {
  const { questionnaireContext } = props;

  const requireCapitalCommitment = questionnaireContext.investorFund && 
  questionnaireContext.investorFund.fund &&
  questionnaireContext.investorFund.fund.requireCapitalCommitment === "no" 
  ? false : true;

  const { includesFormPFQuestion, includesRegulatoryFund } = questionnaireContext.fund;

  const userRole = questionnaireContext && 
  questionnaireContext.userRole && 
  questionnaireContext.userRole === 'admin' && 
  questionnaireContext.investorFund.createdRole &&
  questionnaireContext.investorFund.createdRole === 'admin' ? false : true;

  const isAdminUserRequired = (key) => {
    if (key === 'name') {
      return true
    } else if (key === 'capitalCommitment') {
      return requireCapitalCommitment
    } else {
      return userRole
    }
  } 
  
  let sigEnabled = true;
  if (
    questionnaireContext.investorFund.questionnaire &&
    questionnaireContext.investorFund.questionnaire.questions
  ) {
    const tempMissingParts = {};
    const tempMissingGeneralInfo = [];
    const tempMissingQuestions = [];
    const getValidationTextByIqVersion = (_version) => {
      const lpName = 'Name of Limited Partner';
      const llcName = 'Name of Non Managing Member';
      switch (_version) {
        // only cases for now returning llcName , all llc versions
        case LimitedLiabilityCompanyV1:
        case LimitedLiabilityCompanyV2:
        case LimitedLiabilityCompanyV3:
        case LimitedLiabilityCompanyV4:
        case LimitedLiabilityCompanyV5:
        case LimitedLiabilityCompanyV6:
        case LimitedLiabilityCompanyV6_1:
        case LimitedLiabilityCompanyV7:
        case LimitedLiabilityCompanyV8:
        case LimitedLiabilityCompanyV9:
        case LimitedLiabilityCompanyV10:
          return llcName;

        default:
          return lpName;
      }
    };

    const currentIQVersion =
      questionnaireContext.investorFund.questionnaire.iqVersion;
    const isAccel = currentIQVersion && (
      currentIQVersion.includes(AccelV1) || 
      currentIQVersion.includes(AccelV2) || 
      currentIQVersion.includes(AccelV3) || 
      currentIQVersion.includes(AccelV4)) 
    const extraSectionPart1 = isAccel ? '2' : '1'
    const extraSectionPart2 = isAccel ? '3' : '2'
    let isNaturalPerson = false;
    if (
      questionnaireContext &&
      questionnaireContext.investorFund &&
      questionnaireContext.investorFund.generalInfo
    ) {
      isNaturalPerson =
        questionnaireContext.investorFund.generalInfo.types.indexOf(
          'Natural Person'
        ) > -1 ||
        questionnaireContext.investorFund.generalInfo.types.indexOf(
          'IRA'
        ) > -1 ||
        questionnaireContext.investorFund.generalInfo.types.indexOf(
          'Self Directed IRA or 401(k)'
        ) > -1 ||
        questionnaireContext.investorFund.generalInfo.types.indexOf(
          'Tenants in Common'
        ) > -1 ||
        questionnaireContext.investorFund.generalInfo.types.indexOf(
          'Joint Tenants with Right of Survivorship'
        ) > -1 ||
        questionnaireContext.investorFund.generalInfo.types.indexOf(
          'Other - Individual'
        ) > -1;
    }

    const generalInfoFields = {
      name: getValidationTextByIqVersion(questionnaireContext.currentVersion),
      types: [],
      namePrimaryContact: 'Primary Contact',
      emailPrimaryContact: 'Primary Contact Email',
      addressPrimaryContact: 'Primary Contact Address',
      // phonePrimaryContact: 'Primary Contact Phone',
      capitalCommitment: 'Capital Commitment',
    };
    if (!isNaturalPerson  && currentIQVersion) {
      if (
        (currentIQVersion.includes(OSAGEV1) || currentIQVersion.includes(OSAGEV2) || currentIQVersion.includes(OSAGEV3)) &&
        questionnaireContext.investorFund.generalInfo.disregardedEntity === true
      ) {
        generalInfoFields.ownerDisregardedEntityName =
          'Owner Disregarded Entity Name';
        generalInfoFields.ownerDisregardedEntityLegal = 'Legal Classification';
      }
    }
    const requiredQuestions = {
      id_investor_status_1: 'Natural Person',
      id_investor_status_2: 'U.S. Person (U.S. Securities Act)',
      id_investor_status_3: 'U.S. Person (U.S. Internal Revenue Code)',
      id_investor_status_4: 'FINRA Affiliation',
      id_investor_status_5_a: 'FOIA (a)',
      id_investor_status_5_b: 'FOIA (b)',
      id_investor_status_5_c: 'FOIA (c)',
      id_investor_status_5_d: 'FOIA (d)',
      id_investor_status_5_e: 'FOIA (e)',
      id_investor_status_6: 'Bad Actor',
      id_wiring_bank_status_1: 'FATF Country',
      id_wiring_bank_status_2: 'Customer of the Wiring Bank',
    };


    const requiredExemptedEntityStatus = [
      'id_exempted_entity_status_a',
      'id_exempted_entity_status_b',
      'id_exempted_entity_status_c',
      'id_exempted_entity_status_d',
      'id_exempted_entity_status_e',
      'id_exempted_entity_status_f',
      'id_exempted_entity_status_g',
     'id_exempted_entity_status_h',
      'id_exempted_entity_status_i',
    ]

    const requiredCoveredInvestorStatus = [
      'id_covered_investor_status_a',
      'id_covered_investor_status_b',
      'id_covered_investor_status_or',
    ]

    const requiredRestrictedPerson = [ //Determination of Restricted Person Status
      'id_restricted_person_status_a',
      'id_restricted_person_status_b',
      'id_restricted_person_status_c',
      'id_restricted_person_status_d',
      'id_restricted_person_status_e',
      'id_restricted_person_status_f',
      'id_restricted_person_status_g',
      'id_restricted_person_status_h',
      'id_restricted_person_status_i',
      'id_restricted_person_status_j',
      'id_restricted_person_status_k',
      'id_restricted_person_status_or',
    ]
    // FIXME: example of changes required to favor more configurable versions , since this in only valid for a given fund type in this case fund type HCM
    // this is a single selection required and some options require more steps to select and type

   

    const requiredRepresentationsAndWarranties7 = [
        'id_girw_7_a',
        'id_girw_7_b',
        'id_girw_7_c',
        'id_girw_7_d',
        'id_girw_7_e',
        'id_girw_7_f',
        'id_girw_7_g',
        'id_girw_7_h',
        'id_girw_7_i',
        'id_girw_7_2_a',
        'id_girw_7_2_b',
        'id_girw_7_2_c',
        'id_girw_7_2_d',
        'id_girw_7_2_e',
        'id_girw_7_2_f',
    ]
   
    const requiredRepresentationsAndWarranties8 = [
      'id_girw_8_1_a',
      'id_girw_8_1_b',
      'id_girw_8_1_c',
      'id_girw_8_1_d',
      'id_girw_8_1_e',
      'id_girw_8_1_f',
      'id_girw_8_1_g',
      'id_girw_8_1_h',
      'id_girw_8_1_i',
      'id_girw_8_1_j',
      'id_girw_8_1_k',
      'id_girw_8_1_l',
      ]

    const requireHCMQuestions = {
      id_investor_status_7_hcm_a: 'HCM A',
      id_investor_status_7_hcm_b: 'HCM B',
      id_investor_status_7_hcm_c: 'HCM C',
      id_investor_status_7_hcm_d: 'HCM D',
      id_investor_status_7_hcm_e: 'HCM E',
      id_investor_status_7_hcm_f: 'HCM F',
      id_investor_status_7_hcm_g: 'HCM G',
      id_investor_status_7_hcm_h: 'HCM H',
      id_investor_status_7_hcm_i: 'HCM I',
      id_investor_status_7_hcm_j: 'HCM J',
      id_investor_status_7_hcm_k: 'HCM K',
      id_investor_status_7_hcm_l: 'HCM L',
      id_investor_status_7_hcm_m: 'HCM M',
      id_investor_status_7_hcm_n: 'HCM N',
      id_investor_status_7_hcm_other: 'HCM Other',
      id_investor_status_7_hcm_other_trigger: 'HCM Other Text',
      id_investor_status_7_hcm_m_trigger: 'HCM M Select',
    };
    const requiredNonNaturalPersonQuestions = {
      id_special_entities_1_a: 'ERISA Partner (a)',
      id_special_entities_2_a: 'Potential Lookthrough Entity (a)',
      id_special_entities_2_b: 'Potential Lookthrough Entity (b)',
      id_special_entities_2_c: 'Potential Lookthrough Entity (c)',
      id_special_entities_2_d: 'Potential Lookthrough Entity (d)',
      id_special_entities_3_a: 'Government Entity',
      id_special_entities_4: 'Bank Holding Company Partner',
      id_special_entities_5: 'Private Foundation Partner',
      id_special_entities_6: 'Public Pension Partner',
      id_special_entities_7: 'Fund-of-Funds',
      id_special_entities_8: 'United States Tax-Exempt Partner',
      id_special_entities_9_a: 'Flow-Through Entity (a)',
    };
    if (currentIQVersion && 
      currentIQVersion.includes(LPABBRV1) || 
      currentIQVersion.includes(LPABBRV1_1) || 
      currentIQVersion.includes(LPABBRV2) ||
      currentIQVersion.includes(LPABBRV3) ||
      currentIQVersion.includes(LPABBRV4) ||
      currentIQVersion.includes(LPABBRV5) ||
      currentIQVersion.includes(IOLARV1) || 
      currentIQVersion.includes(IOLARV2) || 
      currentIQVersion.includes(IOLARV3)) {
      delete requiredNonNaturalPersonQuestions['id_special_entities_4'];
      delete requiredNonNaturalPersonQuestions['id_special_entities_5'];
      delete requiredNonNaturalPersonQuestions['id_special_entities_6'];
      delete requiredNonNaturalPersonQuestions['id_special_entities_3_a'];
      delete requiredQuestions['id_investor_status_3'];
      delete requiredQuestions['id_investor_status_4'];
      delete requiredQuestions['id_investor_status_6'];
      delete requiredQuestions['id_wiring_bank_status_1'];
      delete requiredQuestions['id_wiring_bank_status_2'];
    }
    if (currentIQVersion && (currentIQVersion.includes(AccelMainV1) ||  currentIQVersion.includes(AccelMainV2))) {
      delete requiredNonNaturalPersonQuestions['id_special_entities_2_c'];
      delete requiredNonNaturalPersonQuestions['id_special_entities_2_d'];
      delete requiredNonNaturalPersonQuestions['id_special_entities_9_a'];
      delete requiredNonNaturalPersonQuestions['id_special_entities_2_b'];
      delete requiredQuestions['id_investor_status_3'];
    }
    if (isNaturalPerson && currentIQVersion && (currentIQVersion.includes(AccelMainV1) ||  currentIQVersion.includes(AccelMainV2))) {
        delete requiredQuestions['id_investor_status_5_a'];
        delete requiredQuestions['id_investor_status_5_b'];
        delete requiredQuestions['id_investor_status_1'];
        delete requiredQuestions['id_investor_status_2'];
        delete requiredQuestions['id_investor_status_4'];
        delete requiredQuestions['id_investor_status_5_c'];
        delete requiredQuestions['id_investor_status_5_d'];
        delete requiredQuestions['id_investor_status_5_e'];
        delete requiredQuestions['id_investor_status_6'];
      }
     if (currentIQVersion && (currentIQVersion.includes(AccelV1) || 
        currentIQVersion.includes(AccelV2) || 
        currentIQVersion.includes(AccelV3) ||
        currentIQVersion.includes(AccelV4) ||
        currentIQVersion.includes(AccelEntrepreneursV1) ||
        currentIQVersion.includes(AccelEntrepreneursV2))) {
      delete requiredNonNaturalPersonQuestions['id_special_entities_4'];
      delete requiredNonNaturalPersonQuestions['id_special_entities_5'];
      delete requiredNonNaturalPersonQuestions['id_special_entities_6'];
      delete requiredNonNaturalPersonQuestions['id_special_entities_3_a'];
      delete requiredNonNaturalPersonQuestions['id_special_entities_1_a'];
      delete requiredNonNaturalPersonQuestions['id_special_entities_7'];
      delete requiredNonNaturalPersonQuestions['id_special_entities_8'];
      delete requiredQuestions['id_investor_status_3'];
      delete requiredQuestions['id_investor_status_4'];
      delete requiredQuestions['id_investor_status_6'];
      delete requiredQuestions['id_wiring_bank_status_1'];
      delete requiredQuestions['id_wiring_bank_status_2'];
      delete requiredQuestions['id_investor_status_5_d'];
      delete requiredQuestions['id_investor_status_5_e'];
      delete requiredQuestions['id_investor_status_5_f'];
    }
    if (currentIQVersion && currentIQVersion.includes(LPMULTIFUNDV1) || 
        currentIQVersion.includes(LPMULTIFUNDV2) || 
        currentIQVersion.includes(LPMULTIFUNDV3) || 
        currentIQVersion.includes(LPMULTIFUNDV4)) {
      delete requiredNonNaturalPersonQuestions['id_special_entities_4'];
      delete requiredNonNaturalPersonQuestions['id_special_entities_5'];
      delete requiredNonNaturalPersonQuestions['id_special_entities_6'];
      delete requiredNonNaturalPersonQuestions['id_special_entities_3_a'];
      delete requiredQuestions['id_investor_status_3'];
      delete requiredQuestions['id_investor_status_4'];
      delete requiredQuestions['id_investor_status_6'];
      delete requiredQuestions['id_wiring_bank_status_1'];
      delete requiredQuestions['id_wiring_bank_status_2'];
    }
    if (currentIQVersion && currentIQVersion.includes(OSAGEV1) ||
       currentIQVersion.includes(OSAGEV2) ||
       currentIQVersion.includes(OSAGEV3) ||
       currentIQVersion.includes(SROneV1) || 
       currentIQVersion.includes(SROneV2)) {
      delete requiredQuestions['id_investor_status_4'];
    }
    const requiredNaturalPersonCheckBoxQuestions = {
      investor: {
        title: 'Accredited Investor Representations',
        questions: [
          iqID.id_investor_representations_1,
          iqID.id_investor_representations_2,
          iqID.id_investor_representations_or,
        ],
      },
      purchaser: {
        title: 'Qualified Purchaser Representations',
        questions: [
          iqID.id_purchase_representations_1,
          iqID.id_purchase_representations_2,
          iqID.id_purchase_representations_or,
        ],
      },
      client: {
        title: 'Qualified Client Representations',
        questions: [
          iqID.id_client_representations_1,
          iqID.id_client_representations_2,
          iqID.id_client_representations_or,
        ],
      },
    };
    if (currentIQVersion && (
    currentIQVersion.includes(AccelV1) || 
    currentIQVersion.includes(AccelV2) || 
    currentIQVersion.includes(AccelV3) ||
    currentIQVersion.includes(AccelV4) || 
    currentIQVersion.includes(AccelEntrepreneursV1) ||
    currentIQVersion.includes(AccelEntrepreneursV2))) {
			delete requiredNaturalPersonCheckBoxQuestions['client'];
		}

    const formattedQuestions = getInvestorFundQuestions(
      questionnaireContext.investorFund.questionnaire.questions
    );

    if (!formattedQuestions.naturalPerson || 
      formattedQuestions.naturalPerson.answer  === '' ||
      formattedQuestions.naturalPerson.answer === " ") {
        tempMissingQuestions.push('Natural Person');
    }

    if (currentIQVersion && currentIQVersion.includes(OSAGEV1)) {
      if (isNaturalPerson) {
        if (
          !formattedQuestions.rule5130_finraNewIssues_1 &&
          !formattedQuestions.rule5130_finraNewIssues_2
        ) {
          requiredQuestions.finraRule5130 = 'FINRA Rule 5130';
        }
        if (
          !formattedQuestions.rule5131_finraNewIssues_1 &&
          !formattedQuestions.rule5131_finraNewIssues_2
        ) {
          requiredQuestions.finraRule5131 = 'FINRA Rule 5131';
        }
      } else {
        if (
          !formattedQuestions.rule5130_finraNewIssues_1 &&
          !formattedQuestions.rule5130_finraNewIssues_1_opt_1 &&
          !formattedQuestions.rule5130_finraNewIssues_1_opt_2 &&
          !formattedQuestions.rule5130_finraNewIssues_1_opt_3 &&
          !formattedQuestions.rule5130_finraNewIssues_2
        ) {
          requiredQuestions.finraRule5130 = 'FINRA Rule 5130';
        }
        if (
          !formattedQuestions.rule5131_finraNewIssues_1 &&
          !formattedQuestions.rule5131_finraNewIssues_2
        ) {
          requiredQuestions.finraRule5131 = 'FINRA Rule 5131';
        }
      }
    }

    if (
      formattedQuestions.usPerson &&
      formattedQuestions.usPerson.answerIdentifier === ''
    ) {
      requiredQuestions.usPerson = 'U.S. Person (U.S. Securities Act)';
    }
    if (
      formattedQuestions.usPersonIRC &&
      formattedQuestions.usPersonIRC.answerIdentifier === '' &&
      !currentIQVersion.includes(LPABBRV1) &&
      !currentIQVersion.includes(LPABBRV1_1) &&
      !currentIQVersion.includes(LPABBRV2) &&
      !currentIQVersion.includes(LPABBRV3) &&
      !currentIQVersion.includes(LPABBRV4) &&
      !currentIQVersion.includes(LPABBRV5) &&
      !currentIQVersion.includes(AccelV1) &&
      !currentIQVersion.includes(AccelV2) &&
      !currentIQVersion.includes(AccelV3) &&
      !currentIQVersion.includes(AccelV4) &&
      !currentIQVersion.includes(AccelEntrepreneursV1) &&
      !currentIQVersion.includes(AccelEntrepreneursV2) &&
      !currentIQVersion.includes(LPMULTIFUNDV1) &&
      !currentIQVersion.includes(LPMULTIFUNDV2) &&
      !currentIQVersion.includes(LPMULTIFUNDV3) &&
      !currentIQVersion.includes(LPMULTIFUNDV4) &&
      !currentIQVersion.includes(IOLARV1) &&
      !currentIQVersion.includes(IOLARV2) &&
      !currentIQVersion.includes(IOLARV3) &&
      !currentIQVersion.includes(AccelMainV1) &&
      !currentIQVersion.includes(AccelMainV2)
    ) {
      requiredQuestions.usPersonIRC =
        'U.S. Person (U.S. Internal Revenue Code)';
    }
    if (
      formattedQuestions.finraAffiliation &&
      formattedQuestions.finraAffiliation.answerIdentifier === '' &&
      !currentIQVersion.includes(OSAGEV1) &&
      !currentIQVersion.includes(OSAGEV2) &&
      !currentIQVersion.includes(OSAGEV3) &&
      !currentIQVersion.includes(LPABBRV1) &&
      !currentIQVersion.includes(LPABBRV1_1) &&
      !currentIQVersion.includes(LPABBRV2) &&
      !currentIQVersion.includes(LPABBRV3) &&
      !currentIQVersion.includes(LPABBRV4) &&
      !currentIQVersion.includes(LPABBRV5) &&
      !currentIQVersion.includes(AccelV1) &&
      !currentIQVersion.includes(AccelV2) &&
      !currentIQVersion.includes(AccelV3) &&
      !currentIQVersion.includes(AccelV4) &&
      !currentIQVersion.includes(AccelEntrepreneursV1) &&
      !currentIQVersion.includes(AccelEntrepreneursV2) &&
      !currentIQVersion.includes(LPMULTIFUNDV1) &&
      !currentIQVersion.includes(LPMULTIFUNDV2) &&
      !currentIQVersion.includes(LPMULTIFUNDV3) &&
      !currentIQVersion.includes(LPMULTIFUNDV4) &&
      !currentIQVersion.includes(IOLARV1) &&
      !currentIQVersion.includes(IOLARV2) &&
      !currentIQVersion.includes(IOLARV3) &&
      !currentIQVersion.includes(SROneV1) &&
      !currentIQVersion.includes(SROneV2)
    ) {
      requiredQuestions.finraAffiliation = 'FINRA Affiliation';
    }
    if (
      formattedQuestions.foia_a &&
      formattedQuestions.foia_a.answerIdentifier === ''
    ) {
      requiredQuestions.foia_a = 'FOIA(a)';
    }
    if (
      formattedQuestions.foia_b &&
      formattedQuestions.foia_b.answerIdentifier === ''
    ) {
      requiredQuestions.foia_b = 'FOIA(b)';
    }
    if (
      formattedQuestions.foia_c &&
      formattedQuestions.foia_c.answerIdentifier === ''
    ) {
      requiredQuestions.foia_c = 'FOIA(c)';
    }
    if (
      formattedQuestions.foia_d &&
      formattedQuestions.foia_d.answerIdentifier === '' &&
      !currentIQVersion.includes(AccelV1) &&
      !currentIQVersion.includes(AccelV2) &&
      !currentIQVersion.includes(AccelV3) &&
      !currentIQVersion.includes(AccelV4) &&
      !currentIQVersion.includes(AccelEntrepreneursV1) &&
      !currentIQVersion.includes(AccelEntrepreneursV2)
    ) {
      requiredQuestions.foia_d = 'FOIA(d)';
    }
    if (
      formattedQuestions.foia_e &&
      formattedQuestions.foia_e.answerIdentifier === '' &&
      !currentIQVersion.includes(AccelV1) &&
      !currentIQVersion.includes(AccelV2) &&
      !currentIQVersion.includes(AccelV3) &&
      !currentIQVersion.includes(AccelV4) &&
      !currentIQVersion.includes(AccelEntrepreneursV1) &&
      !currentIQVersion.includes(AccelEntrepreneursV2)
    ) {
      requiredQuestions.foia_e = 'FOIA(e)';
    }

    if (
      formattedQuestions.badActor &&
      formattedQuestions.badActor.answerIdentifier === '' &&
      !currentIQVersion.includes(LPABBRV1) &&
      !currentIQVersion.includes(LPABBRV1_1) &&
      !currentIQVersion.includes(LPABBRV2) &&
      !currentIQVersion.includes(LPABBRV3) &&
      !currentIQVersion.includes(LPABBRV4) &&
      !currentIQVersion.includes(LPABBRV5) &&
      !currentIQVersion.includes(AccelV1) &&
      !currentIQVersion.includes(AccelV2) &&
      !currentIQVersion.includes(AccelV3) &&
      !currentIQVersion.includes(AccelV4) &&
      !currentIQVersion.includes(AccelEntrepreneursV1) &&
      !currentIQVersion.includes(AccelEntrepreneursV2) &&
      !currentIQVersion.includes(LPMULTIFUNDV1) &&
      !currentIQVersion.includes(LPMULTIFUNDV2) &&
      !currentIQVersion.includes(LPMULTIFUNDV3) &&
      !currentIQVersion.includes(LPMULTIFUNDV4) &&
      !currentIQVersion.includes(IOLARV1) &&
      !currentIQVersion.includes(IOLARV2) &&
      !currentIQVersion.includes(IOLARV3)
    ) {
      requiredQuestions.finraAffiliation = 'Bad Actor';
    }

    if (currentIQVersion) {
      if (
        currentIQVersion.includes(ForesiteV1) ||
        currentIQVersion.includes(ForesiteV2) ||
        currentIQVersion.includes(ForesiteV3) ||
        currentIQVersion.includes(ForesiteV4) ||
        currentIQVersion.includes(ForesiteV5) ||
        currentIQVersion.includes(ForesiteV6) ||
        currentIQVersion.includes(ForesiteV7) ||
        currentIQVersion.includes(ForesiteV8) ||
        currentIQVersion.includes(ForesiteLabsV1) ||
        currentIQVersion.includes(ForesiteLabsV2) ||
        currentIQVersion.includes(ForesiteLabsV3) ||
        currentIQVersion.includes(ForesiteLabsV4) ||
        currentIQVersion.includes(ForesiteLabsV5)
      ) {
        if (!formattedQuestions.foresite_investor_type) {
          requiredQuestions.foresite_investor_type = 'Foresite Investor Type';
        }

        requiredQuestions.id_foresiteFinraAffiliation =
          'Foresite FINRA Affiliation';
        if (formattedQuestions.foresiteFinraAffiliation) {
          if (formattedQuestions.foresiteFinraAffiliation.answer === 'Yes') {
            requiredQuestions.id_foresiteFinraAffiliationFurtherDetails =
              'Foresite FINRA Affiliation Details';
          }
        }

        requiredQuestions.id_foresitePublicCompanyAffiliation =
          'Foresite Public Company Affiliation';
        if (formattedQuestions.foresitePublicCompanyAffiliation) {
          if (
            formattedQuestions.foresitePublicCompanyAffiliation.answer === 'Yes'
          ) {
            requiredQuestions.id_foresitePublicCompanyAffiliation_opt =
              'Foresite Public Company Affiliation Details';
          }
        }

        requiredQuestions.id_foresiteEntities = 'Foresite Entities';
        if (formattedQuestions.foresiteEntities) {
          if (formattedQuestions.foresiteEntities.answer === 'Yes') {
            requiredQuestions.id_foresiteEntities_opt =
              'Foresite Entities Details';
          }
        }
      }
    }
    const hasForeign =
      currentIQVersion &&
      (currentIQVersion.includes(LimitedPartnerV2) ||
        currentIQVersion.includes(LimitedPartnerV3) ||
        currentIQVersion.includes(LimitedPartnerV4) ||
        currentIQVersion.includes(LimitedPartnerV5) ||
        currentIQVersion.includes(LimitedPartnerV6) ||
        currentIQVersion.includes(LimitedPartnerV7) ||
        currentIQVersion.includes(LimitedPartnerV8) ||
        currentIQVersion.includes(LimitedPartnerV8_1) ||
        currentIQVersion.includes(LimitedPartnerV9) ||
        currentIQVersion.includes(LimitedPartnerV10) ||
        currentIQVersion.includes(LimitedPartnerV11) ||
        currentIQVersion.includes(LimitedPartnerV12) ||
        currentIQVersion.includes(FrazierV1) ||
        currentIQVersion.includes(FrazierV2) ||
        currentIQVersion.includes(LimitedPartnershipMFV1) ||
        currentIQVersion.includes(LimitedPartnershipMFV2) ||
        currentIQVersion.includes(LimitedPartnershipMFV3) ||
        currentIQVersion.includes(LimitedPartnershipMFV4) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV1) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV2) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV3) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV4) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV5) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV6) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV6_1) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV7) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV8) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV9) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV10) ||
        currentIQVersion.includes(QuestaCapitalV1) ||
        currentIQVersion.includes(QuestaCapitalV2) ||
        currentIQVersion.includes(QuestaCapitalV3) ||
        currentIQVersion.includes(QuestaCapitalV4) ||
        currentIQVersion.includes(QuestaCapitalV5) ||
        currentIQVersion.includes(QuestaCapitalV6) ||
        currentIQVersion.includes(QuestaCapitalV7) ||
        currentIQVersion.includes(QuestaCapitalV8) ||
        currentIQVersion.includes(ForesiteV1) ||
        currentIQVersion.includes(ForesiteV2) ||
        currentIQVersion.includes(ForesiteV3) ||
        currentIQVersion.includes(ForesiteV4) ||
        currentIQVersion.includes(ForesiteV5) ||
        currentIQVersion.includes(ForesiteV6) ||
        currentIQVersion.includes(ForesiteV7) ||
        currentIQVersion.includes(ForesiteV8) ||
        currentIQVersion.includes(InitializedV1) ||
        currentIQVersion.includes(InitializedV2) ||
        currentIQVersion.includes(InitializedV3) ||
        currentIQVersion.includes(InitializedV4) ||
        currentIQVersion.includes(InitializedV5) ||
        currentIQVersion.includes(InitializedV6) ||
        currentIQVersion.includes(InitializedV7) ||
        currentIQVersion.includes(InitializedV8) ||
        currentIQVersion.includes(HCMV1) ||
        currentIQVersion.includes(HCMV2) ||
        currentIQVersion.includes(HCMV3) ||
        currentIQVersion.includes(HCMV4) ||
        currentIQVersion.includes(HCMV5) ||
        currentIQVersion.includes(ForesiteLabsV1) ||
        currentIQVersion.includes(ForesiteLabsV2) ||
        currentIQVersion.includes(ForesiteLabsV3) ||
        currentIQVersion.includes(ForesiteLabsV4) ||
        currentIQVersion.includes(ForesiteLabsV5) ||
        currentIQVersion.includes(LPABBRV1) ||
        currentIQVersion.includes(LPABBRV1_1) ||
        currentIQVersion.includes(LPABBRV2) ||
        currentIQVersion.includes(LPABBRV3) ||
        currentIQVersion.includes(LPABBRV4) ||
        currentIQVersion.includes(LPABBRV5) ||
        currentIQVersion.includes(IOLARV1) ||
        currentIQVersion.includes(IOLARV2) ||
        currentIQVersion.includes(IOLARV3) ||
        currentIQVersion.includes(OSAGEV1) ||
        currentIQVersion.includes(OSAGEV2) ||
        currentIQVersion.includes(OSAGEV3) ||
        currentIQVersion.includes(NewLimitedPartnerV1) ||
        currentIQVersion.includes(NewLimitedPartnerV2) ||
        currentIQVersion.includes(NewLimitedPartnerV3) ||
        currentIQVersion.includes(TcgCrossOverV1) ||
        currentIQVersion.includes(TcgCrossOverV2) ||
        currentIQVersion.includes(TcgCrossOverV3) ||
        currentIQVersion.includes(BITKRAFTV1) ||
        currentIQVersion.includes(BITKRAFTV2) ||
        currentIQVersion.includes(BITKRAFTV3) ||
        currentIQVersion.includes(ElephantPartnerV1) ||
        currentIQVersion.includes(ElephantPartnerV2) ||
        currentIQVersion.includes(ElephantPartnerV3) ||
        currentIQVersion.includes(FireworkV1) ||
        currentIQVersion.includes(FireworkV2) ||
        currentIQVersion.includes(FireworkV3) ||
        currentIQVersion.includes(PrometheanV1) ||
        currentIQVersion.includes(PrometheanV2) ||
        currentIQVersion.includes(PrometheanV3) ||
        currentIQVersion.includes(AccelV1) ||
        currentIQVersion.includes(AccelV2) ||
        currentIQVersion.includes(AccelV3) ||
        currentIQVersion.includes(AccelV4) ||
        currentIQVersion.includes(AccelMainV1) ||
        currentIQVersion.includes(AccelMainV2) ||
        currentIQVersion.includes(AccelEntrepreneursV1) ||
        currentIQVersion.includes(AccelEntrepreneursV2) ||
        currentIQVersion.includes(AudereV1) ||
        currentIQVersion.includes(AudereV2) ||
        currentIQVersion.includes(AudereV3) ||
        currentIQVersion.includes(CaymanV1) ||
        currentIQVersion.includes(CaymanV2) ||
        currentIQVersion.includes(CaymanV3) ||
        currentIQVersion.includes(CaymanV4) ||
        currentIQVersion.includes(LPMULTIFUNDV1) ||
        currentIQVersion.includes(LPMULTIFUNDV2) ||
        currentIQVersion.includes(LPMULTIFUNDV3) ||
        currentIQVersion.includes(LPMULTIFUNDV4) ||
        currentIQVersion.includes(SROneV1) || 
        currentIQVersion.includes(SROneV2)
        );
    const hasForeign2 =
      currentIQVersion &&
      (currentIQVersion.includes(LimitedPartnerV3) ||
        currentIQVersion.includes(LimitedPartnerV3) ||
        currentIQVersion.includes(LimitedPartnerV4) ||
        currentIQVersion.includes(LimitedPartnerV5) ||
        currentIQVersion.includes(LimitedPartnerV6) ||
        currentIQVersion.includes(LimitedPartnerV7) ||
        currentIQVersion.includes(LimitedPartnerV8) ||
        currentIQVersion.includes(LimitedPartnerV8_1) ||
        currentIQVersion.includes(LimitedPartnerV9) ||
        currentIQVersion.includes(LimitedPartnerV10) ||
        currentIQVersion.includes(LimitedPartnerV11) ||
        currentIQVersion.includes(LimitedPartnerV12) ||
        currentIQVersion.includes(FrazierV1) ||
        currentIQVersion.includes(FrazierV2) ||
        currentIQVersion.includes(LimitedPartnershipMFV1) ||
        currentIQVersion.includes(LimitedPartnershipMFV2) ||
        currentIQVersion.includes(LimitedPartnershipMFV3) ||
        currentIQVersion.includes(LimitedPartnershipMFV4) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV1) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV2) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV3) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV4) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV5) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV6) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV6_1) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV7) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV8) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV9) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV10) ||
        currentIQVersion.includes(QuestaCapitalV1) ||
        currentIQVersion.includes(QuestaCapitalV2) ||
        currentIQVersion.includes(QuestaCapitalV3) ||
        currentIQVersion.includes(QuestaCapitalV4) ||
        currentIQVersion.includes(QuestaCapitalV5) ||
        currentIQVersion.includes(QuestaCapitalV6) ||
        currentIQVersion.includes(QuestaCapitalV7) ||
        currentIQVersion.includes(QuestaCapitalV8) ||
        currentIQVersion.includes(ForesiteV1) ||
        currentIQVersion.includes(ForesiteV2) ||
        currentIQVersion.includes(ForesiteV3) ||
        currentIQVersion.includes(ForesiteV4) ||
        currentIQVersion.includes(ForesiteV5) ||
        currentIQVersion.includes(ForesiteV6) ||
        currentIQVersion.includes(ForesiteV7) ||
        currentIQVersion.includes(ForesiteV8) ||
        currentIQVersion.includes(InitializedV1) ||
        currentIQVersion.includes(InitializedV2) ||
        currentIQVersion.includes(InitializedV3) ||
        currentIQVersion.includes(InitializedV4) ||
        currentIQVersion.includes(InitializedV5) ||
        currentIQVersion.includes(InitializedV6) ||
        currentIQVersion.includes(InitializedV7) ||
        currentIQVersion.includes(InitializedV8) ||
        currentIQVersion.includes(HCMV1) ||
        currentIQVersion.includes(HCMV2) ||
        currentIQVersion.includes(HCMV3) ||
        currentIQVersion.includes(HCMV4) ||
        currentIQVersion.includes(HCMV5) ||
        currentIQVersion.includes(ForesiteLabsV1) ||
        currentIQVersion.includes(ForesiteLabsV2) ||
        currentIQVersion.includes(ForesiteLabsV3) ||
        currentIQVersion.includes(ForesiteLabsV4) ||
        currentIQVersion.includes(ForesiteLabsV5) ||
        currentIQVersion.includes(LPABBRV1) ||
        currentIQVersion.includes(LPABBRV1_1) ||
        currentIQVersion.includes(LPABBRV2) ||
        currentIQVersion.includes(LPABBRV3) ||
        currentIQVersion.includes(LPABBRV4) ||
        currentIQVersion.includes(LPABBRV5) ||
        currentIQVersion.includes(IOLARV1) ||
        currentIQVersion.includes(IOLARV2) ||
        currentIQVersion.includes(IOLARV3) ||
        currentIQVersion.includes(OSAGEV1) ||
        currentIQVersion.includes(OSAGEV2) ||
        currentIQVersion.includes(OSAGEV3) ||
        currentIQVersion.includes(NewLimitedPartnerV1) ||
        currentIQVersion.includes(NewLimitedPartnerV2) ||
        currentIQVersion.includes(NewLimitedPartnerV3) ||
        currentIQVersion.includes(TcgCrossOverV1) ||
        currentIQVersion.includes(TcgCrossOverV2) ||
        currentIQVersion.includes(TcgCrossOverV3) ||
        currentIQVersion.includes(BITKRAFTV1) ||
        currentIQVersion.includes(BITKRAFTV2) ||
        currentIQVersion.includes(BITKRAFTV3) ||
        currentIQVersion.includes(ElephantPartnerV1) ||
        currentIQVersion.includes(ElephantPartnerV2) ||
        currentIQVersion.includes(ElephantPartnerV3) ||
        currentIQVersion.includes(FireworkV1) ||
        currentIQVersion.includes(FireworkV2) ||
        currentIQVersion.includes(FireworkV3) ||
        currentIQVersion.includes(PrometheanV1) ||
        currentIQVersion.includes(PrometheanV2) ||
        currentIQVersion.includes(PrometheanV3) ||
        currentIQVersion.includes(AccelV1) ||
        currentIQVersion.includes(AccelV2) ||
        currentIQVersion.includes(AccelV3) ||
        currentIQVersion.includes(AccelV4) ||
        currentIQVersion.includes(AccelMainV1) ||
        currentIQVersion.includes(AccelMainV2) ||
        currentIQVersion.includes(AccelEntrepreneursV1) ||
        currentIQVersion.includes(AccelEntrepreneursV2) ||
        currentIQVersion.includes(AudereV1) ||
        currentIQVersion.includes(AudereV2) ||
        currentIQVersion.includes(AudereV3) ||
        currentIQVersion.includes(CaymanV1) ||
        currentIQVersion.includes(CaymanV2) ||
        currentIQVersion.includes(CaymanV3) ||
        currentIQVersion.includes(CaymanV4) ||
        currentIQVersion.includes(LPMULTIFUNDV1) ||
        currentIQVersion.includes(LPMULTIFUNDV2) ||
        currentIQVersion.includes(LPMULTIFUNDV3) ||
        currentIQVersion.includes(LPMULTIFUNDV4) ||
        currentIQVersion.includes(SROneV1) || 
        currentIQVersion.includes(SROneV2)
      )
    if (currentIQVersion) {
      // This is the latest version to the moment, ideally each question should
      // have a range of version validity , as this condition does not scale well at all when
      // newer versions are added. It becomes hard to track of why a given condition needs to be made
      // from this point and on
      if (
        currentIQVersion.includes(LimitedPartnerV5) ||
        currentIQVersion.includes(LimitedPartnerV6) ||
        currentIQVersion.includes(LimitedPartnerV7) ||
        currentIQVersion.includes(LimitedPartnerV8) ||
        currentIQVersion.includes(LimitedPartnerV8_1) ||
        currentIQVersion.includes(LimitedPartnerV9) ||
        currentIQVersion.includes(LimitedPartnerV10) ||
        currentIQVersion.includes(LimitedPartnerV11) ||
        currentIQVersion.includes(LimitedPartnerV12) ||
        currentIQVersion.includes(FrazierV1) ||
        currentIQVersion.includes(FrazierV2) ||
        currentIQVersion.includes(LimitedPartnershipMFV1) ||
        currentIQVersion.includes(LimitedPartnershipMFV2) ||
        currentIQVersion.includes(LimitedPartnershipMFV3) ||
        currentIQVersion.includes(LimitedPartnershipMFV4) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV4) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV5) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV6) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV6_1) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV7) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV8) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV9) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV10) ||
        currentIQVersion.includes(ForesiteV4) ||
        currentIQVersion.includes(ForesiteV5) ||
        currentIQVersion.includes(ForesiteV6) ||
        currentIQVersion.includes(ForesiteV7) ||
        currentIQVersion.includes(ForesiteV8) ||
        currentIQVersion.includes(InitializedV4) ||
        currentIQVersion.includes(InitializedV5) ||
        currentIQVersion.includes(InitializedV6) ||
        currentIQVersion.includes(InitializedV7) ||
        currentIQVersion.includes(InitializedV8) ||
        currentIQVersion.includes(QuestaCapitalV4) ||
        currentIQVersion.includes(QuestaCapitalV5) ||
        currentIQVersion.includes(QuestaCapitalV6) ||
        currentIQVersion.includes(QuestaCapitalV7) ||
        currentIQVersion.includes(QuestaCapitalV8) ||
        currentIQVersion.includes(HCMV1) ||
        currentIQVersion.includes(HCMV2) ||
        currentIQVersion.includes(HCMV3) ||
        currentIQVersion.includes(HCMV4) ||
        currentIQVersion.includes(HCMV5) ||
        currentIQVersion.includes(ForesiteLabsV1) ||
        currentIQVersion.includes(ForesiteLabsV2) ||
        currentIQVersion.includes(ForesiteLabsV3) ||
        currentIQVersion.includes(ForesiteLabsV4) ||
        currentIQVersion.includes(ForesiteLabsV5) ||
        currentIQVersion.includes(LPABBRV1) ||
        currentIQVersion.includes(LPABBRV1_1) ||
        currentIQVersion.includes(LPABBRV2) ||
        currentIQVersion.includes(LPABBRV3) ||
        currentIQVersion.includes(LPABBRV4) ||
        currentIQVersion.includes(LPABBRV5) ||
        currentIQVersion.includes(IOLARV1) ||
        currentIQVersion.includes(IOLARV2) ||
        currentIQVersion.includes(IOLARV3) ||
        currentIQVersion.includes(OSAGEV1) ||
        currentIQVersion.includes(OSAGEV2) ||
        currentIQVersion.includes(OSAGEV3) ||
        currentIQVersion.includes(NewLimitedPartnerV1) ||
        currentIQVersion.includes(NewLimitedPartnerV2) ||
        currentIQVersion.includes(NewLimitedPartnerV3) ||
        currentIQVersion.includes(TcgCrossOverV1) ||
        currentIQVersion.includes(TcgCrossOverV2) ||
        currentIQVersion.includes(TcgCrossOverV3) ||
        currentIQVersion.includes(BITKRAFTV1) ||
        currentIQVersion.includes(BITKRAFTV2) ||
        currentIQVersion.includes(BITKRAFTV3) ||
        currentIQVersion.includes(ElephantPartnerV1) ||
        currentIQVersion.includes(ElephantPartnerV2) ||
        currentIQVersion.includes(ElephantPartnerV3) ||
        currentIQVersion.includes(FireworkV1)  ||
        currentIQVersion.includes(FireworkV2)  ||
        currentIQVersion.includes(FireworkV3)  ||
        currentIQVersion.includes(PrometheanV1) ||
        currentIQVersion.includes(PrometheanV2) ||
        currentIQVersion.includes(PrometheanV3) ||
        currentIQVersion.includes(AccelV1) ||
        currentIQVersion.includes(AccelV2) ||
        currentIQVersion.includes(AccelV3) ||
        currentIQVersion.includes(AccelV4) ||
        currentIQVersion.includes(AccelEntrepreneursV1) ||
        currentIQVersion.includes(AccelEntrepreneursV2) ||
        currentIQVersion.includes(AccelMainV1) ||
        currentIQVersion.includes(AccelMainV2) ||
        currentIQVersion.includes(AudereV1) ||
        currentIQVersion.includes(AudereV2) ||
        currentIQVersion.includes(AudereV3) ||
        currentIQVersion.includes(CaymanV1) ||
        currentIQVersion.includes(CaymanV2) ||
        currentIQVersion.includes(CaymanV3) ||
        currentIQVersion.includes(CaymanV4) ||
        currentIQVersion.includes(LPMULTIFUNDV1) ||
        currentIQVersion.includes(LPMULTIFUNDV2) ||
        currentIQVersion.includes(LPMULTIFUNDV3) ||
        currentIQVersion.includes(LPMULTIFUNDV4) ||
        currentIQVersion.includes(SROneV1) ||
        currentIQVersion.includes(SROneV2)
      ) {
        requiredNaturalPersonCheckBoxQuestions.investor.questions.push(
          iqID.id_investor_representations_10
        );
        requiredNaturalPersonCheckBoxQuestions.investor.questions.push(
          iqID.id_investor_representations_12
        );
      }
      if (hasForeign) {
        requiredNaturalPersonCheckBoxQuestions.foreign = {
          title: 'Committee on Foreign Investment',
          questions: [iqID.id_foreign_person_1, iqID.id_foreign_person_or],
        };
      }
    }
    // IRA validation condition
    const isIRA =
      questionnaireContext &&
      questionnaireContext.investorFund &&
      questionnaireContext.investorFund.generalInfo &&
      (questionnaireContext.investorFund.generalInfo.types.indexOf('IRA') > -1 || questionnaireContext.investorFund.generalInfo.types.indexOf('Self Directed IRA or 401(k)') > -1);
    const requiredNonNaturalPersonCheckBoxQuestions = {
      investor: {
        title: 'Accredited Investor Representations',
        questions: [
          iqID.id_investor_representations_3,
          iqID.id_investor_representations_4,
          iqID.id_investor_representations_5,
          iqID.id_investor_representations_6,
          iqID.id_investor_representations_7,
          iqID.id_investor_representations_8,
          iqID.id_investor_representations_9,
          iqID.id_investor_representations_or,
        ],
      },
      purchaser: {
        title: 'Qualified Purchaser Representations',
        questions: isIRA
          ? [
              iqID.id_purchase_representations_1,
              iqID.id_purchase_representations_2,
              iqID.id_purchase_representations_3,
              iqID.id_purchase_representations_4,
              iqID.id_purchase_representations_5,
              iqID.id_purchase_representations_6,
              iqID.id_purchase_representations_7,
              iqID.id_purchase_representations_or,
            ]
          : [
              iqID.id_purchase_representations_2,
              iqID.id_purchase_representations_3,
              iqID.id_purchase_representations_4,
              iqID.id_purchase_representations_5,
              iqID.id_purchase_representations_6,
              iqID.id_purchase_representations_7,
              iqID.id_purchase_representations_or,
            ],
      },
      client: {
        title: 'Qualified Client Representations',
        questions: [
          iqID.id_client_representations_1,
          iqID.id_client_representations_2,
          iqID.id_client_representations_or,
        ],
      },
    };

    if (currentIQVersion) {
      // This is the latest version to the moment, ideally each question should
      // have a range of version validity , as this condition does not scale well at all when
      // newer versions are added. It becomes hard to track of why a given condition needs to be made
      // from this point and on
      if (
        currentIQVersion.includes(LimitedPartnerV5) ||
        currentIQVersion.includes(LimitedPartnerV6) ||
        currentIQVersion.includes(LimitedPartnerV7) ||
        currentIQVersion.includes(LimitedPartnerV8) ||
        currentIQVersion.includes(LimitedPartnerV8_1) ||
        currentIQVersion.includes(LimitedPartnerV9) ||
        currentIQVersion.includes(LimitedPartnerV10) ||
        currentIQVersion.includes(LimitedPartnerV11) ||
        currentIQVersion.includes(LimitedPartnerV12) ||
        currentIQVersion.includes(FrazierV1) ||
        currentIQVersion.includes(FrazierV2) ||
        currentIQVersion.includes(LimitedPartnershipMFV1) ||
        currentIQVersion.includes(LimitedPartnershipMFV2) ||
        currentIQVersion.includes(LimitedPartnershipMFV3) ||
        currentIQVersion.includes(LimitedPartnershipMFV4) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV4) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV5) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV6) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV6_1) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV7) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV8) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV9) ||
        currentIQVersion.includes(LimitedLiabilityCompanyV10) ||
        currentIQVersion.includes(ForesiteV4) ||
        currentIQVersion.includes(ForesiteV5) ||
        currentIQVersion.includes(ForesiteV6) ||
        currentIQVersion.includes(ForesiteV7) ||
        currentIQVersion.includes(ForesiteV8) ||
        currentIQVersion.includes(InitializedV4) ||
        currentIQVersion.includes(InitializedV5) ||
        currentIQVersion.includes(InitializedV6) ||
        currentIQVersion.includes(InitializedV7) ||
        currentIQVersion.includes(InitializedV8) ||
        currentIQVersion.includes(QuestaCapitalV4) ||
        currentIQVersion.includes(QuestaCapitalV5) ||
        currentIQVersion.includes(QuestaCapitalV6) ||
        currentIQVersion.includes(QuestaCapitalV7) ||
        currentIQVersion.includes(QuestaCapitalV8) ||
        currentIQVersion.includes(HCMV1) ||
        currentIQVersion.includes(HCMV2) ||
        currentIQVersion.includes(HCMV3) ||
        currentIQVersion.includes(HCMV4) ||
        currentIQVersion.includes(HCMV5) ||
        currentIQVersion.includes(ForesiteLabsV1) ||
        currentIQVersion.includes(ForesiteLabsV2) ||
        currentIQVersion.includes(ForesiteLabsV3) ||
        currentIQVersion.includes(ForesiteLabsV4) ||
        currentIQVersion.includes(ForesiteLabsV5) ||
        currentIQVersion.includes(LPABBRV1) ||
        currentIQVersion.includes(LPABBRV1_1) ||
        currentIQVersion.includes(LPABBRV2) ||
        currentIQVersion.includes(LPABBRV3) ||
        currentIQVersion.includes(LPABBRV4) ||
        currentIQVersion.includes(LPABBRV5) ||
        currentIQVersion.includes(IOLARV1) ||
        currentIQVersion.includes(IOLARV2) ||
        currentIQVersion.includes(IOLARV3) ||
        currentIQVersion.includes(OSAGEV1) ||
        currentIQVersion.includes(OSAGEV2) ||
        currentIQVersion.includes(OSAGEV3) ||
        currentIQVersion.includes(NewLimitedPartnerV1) ||
        currentIQVersion.includes(NewLimitedPartnerV2) ||
        currentIQVersion.includes(NewLimitedPartnerV3) ||
        currentIQVersion.includes(TcgCrossOverV1) ||
        currentIQVersion.includes(TcgCrossOverV2) ||
        currentIQVersion.includes(TcgCrossOverV3) ||
        currentIQVersion.includes(BITKRAFTV1) ||
        currentIQVersion.includes(BITKRAFTV2) ||
        currentIQVersion.includes(BITKRAFTV3) ||
        currentIQVersion.includes(ElephantPartnerV1) ||
        currentIQVersion.includes(ElephantPartnerV2) ||
        currentIQVersion.includes(ElephantPartnerV3) ||
        currentIQVersion.includes(FireworkV1) ||
        currentIQVersion.includes(FireworkV2) ||
        currentIQVersion.includes(FireworkV3) ||
        currentIQVersion.includes(PrometheanV1) ||
        currentIQVersion.includes(PrometheanV2) ||
        currentIQVersion.includes(PrometheanV3) ||
        currentIQVersion.includes(AccelV1) ||
        currentIQVersion.includes(AccelV2) ||
        currentIQVersion.includes(AccelV3) ||
        currentIQVersion.includes(AccelV4) ||
        currentIQVersion.includes(AccelEntrepreneursV1) ||
        currentIQVersion.includes(AccelEntrepreneursV2) ||
        currentIQVersion.includes(AccelMainV1) ||
        currentIQVersion.includes(AccelMainV2) ||
        currentIQVersion.includes(AudereV1) ||
        currentIQVersion.includes(AudereV2) ||
        currentIQVersion.includes(AudereV3) ||
        currentIQVersion.includes(CaymanV1) ||
        currentIQVersion.includes(CaymanV2) ||
        currentIQVersion.includes(CaymanV3) ||
        currentIQVersion.includes(CaymanV4) ||
        currentIQVersion.includes(LPMULTIFUNDV1) ||
        currentIQVersion.includes(LPMULTIFUNDV2) ||
        currentIQVersion.includes(LPMULTIFUNDV3) ||
        currentIQVersion.includes(LPMULTIFUNDV4) ||
        currentIQVersion.includes(SROneV1) ||
        currentIQVersion.includes(SROneV2) 
      ) {
        requiredNonNaturalPersonCheckBoxQuestions.investor.questions.push(
          iqID.id_investor_representations_6_extra
        );
        requiredNonNaturalPersonCheckBoxQuestions.investor.questions.push(
          iqID.id_investor_representations_11
        );
        requiredNonNaturalPersonCheckBoxQuestions.investor.questions.push(
          iqID.id_investor_representations_12
        );
      }
      if (hasForeign) {
        requiredNonNaturalPersonCheckBoxQuestions.foreign = {
          title: 'Committee on Foreign Investment',
          questions: [
            iqID.id_foreign_person_2,
            iqID.id_foreign_person_3,
            iqID.id_foreign_person_4,
            iqID.id_foreign_person_or,
          ],
        };
        // TODO: cleanup validations per version because it is cubersome as we keep adding new versions and some have the validate, so better to keep in question general config FS-372
        if (hasForeign2) {
          requiredNonNaturalPersonCheckBoxQuestions.foreign2 = {
            title: 'Committee on Foreign Investment (2)',
            questions: [
              iqID.id_foreign_person_2_1,
              iqID.id_foreign_person_2_2,
              iqID.id_foreign_person_2_or,
            ],
          };
        }
      }
      if (currentIQVersion.includes(CaymanV3) || currentIQVersion.includes(CaymanV4)) {
        requiredNonNaturalPersonCheckBoxQuestions.investor.questions.push(
          iqID.id_investor_representations_13
        );
        requiredNaturalPersonCheckBoxQuestions.investor.questions.push(
          iqID.id_investor_representations_13
        );
        requiredNaturalPersonCheckBoxQuestions.purchaser.questions.push(
          iqID.id_purchase_representations_8
        );
      }
    }

    const part1Name = `Part ${extraSectionPart1} - General Information`

    const flowThroughEntity = _.find(
      questionnaireContext.investorFund.questionnaire.questions,
      (quest) => quest.descriptionIdentifier === iqID.id_special_entities_9_a
    );
    if (flowThroughEntity && flowThroughEntity.answer === 'Yes') {
      requiredNonNaturalPersonQuestions.id_special_entities_9_b =
        'Flow-Through Entity (b)';
      requiredNonNaturalPersonQuestions.id_special_entities_9_c =
        'Flow-Through Entity (c)';
    }
    Object.keys(generalInfoFields).forEach((key) => {
      // TODO: Refactor this validation
      
      const fundRequired = [
        LPMULTIFUNDV1, LPMULTIFUNDV2, LPMULTIFUNDV3, LPMULTIFUNDV4, 
        AccelV1, AccelV2, AccelV3, AccelV4,
        AccelEntrepreneursV1, AccelEntrepreneursV2, 
        LimitedPartnershipMFV1,  LimitedPartnershipMFV2, 
        LimitedPartnershipMFV2, LimitedPartnershipMFV3, LimitedPartnershipMFV4].some((d) => {return currentIQVersion.includes(d)})

          if (key === 'capitalCommitment' ) {
              if (
              (questionnaireContext.investorFund && questionnaireContext.investorFund.fund.requireCapitalCommitment !== "no") &&
                (!questionnaireContext.investorFund.generalInfo.capitalCommitment ||
                questionnaireContext.investorFund.generalInfo[key] === '0' ||
                questionnaireContext.investorFund.generalInfo[key] === '$0.00' ||
                 questionnaireContext.investorFund.generalInfo[key] === '€0.00' ||
                questionnaireContext.investorFund.generalInfo[key] === 0)
              ) {
                if(!fundRequired){
                  sigEnabled = false;
                  tempMissingParts.part1 = part1Name;
                  tempMissingGeneralInfo.push(generalInfoFields[key]);
                }
              }
          } else if 
           (
            isAdminUserRequired(key) &&
            !questionnaireContext.investorFund.generalInfo[key]  ||
            questionnaireContext.investorFund.generalInfo[key] &&
            questionnaireContext.investorFund.generalInfo[key].length === 0
          ) 
          {
            sigEnabled = false;
            tempMissingParts.part1 = part1Name;
            tempMissingGeneralInfo.push(generalInfoFields[key]);
          }

        if(currentIQVersion.includes(LPMULTIFUNDV1) || 
           currentIQVersion.includes(LPMULTIFUNDV2) || 
           currentIQVersion.includes(LPMULTIFUNDV3) ||
           currentIQVersion.includes(LPMULTIFUNDV4)) {

          const isValidCapitalCommitment = questionnaireContext.investorFund.generalInfo.capitalCommitment === '0' || 
          questionnaireContext.investorFund.generalInfo.capitalCommitment === '$0.00' ||
          questionnaireContext.investorFund.generalInfo.capitalCommitment === '€0.00';
          
          const isValidCapitalCommitment2 = questionnaireContext.investorFund.generalInfo.capitalCommitmentFund2 === '0' || 
          questionnaireContext.investorFund.generalInfo.capitalCommitmentFund2 === '$0.00' ||
          questionnaireContext.investorFund.generalInfo.capitalCommitmentFund2 === '€0.00';

          if (key === 'capitalCommitment') {
            if (
                (!questionnaireContext.investorFund.generalInfo.capitalCommitment && 
                !questionnaireContext.investorFund.generalInfo.capitalCommitmentFund2) ||
                (isValidCapitalCommitment && isValidCapitalCommitment2)
              )
            {
              if(isAdminUserRequired(key)) {
                sigEnabled = false;
                tempMissingParts.part1 = part1Name;
                tempMissingGeneralInfo.push(generalInfoFields[key]);
              }
            }
          } 
        }
        
      if (currentIQVersion.includes(LimitedPartnershipMFV1) || 
          currentIQVersion.includes(LimitedPartnershipMFV2) || 
          currentIQVersion.includes(LimitedPartnershipMFV3) ||
          currentIQVersion.includes(LimitedPartnershipMFV4)) {
				const isValidCapitalCommitment =
					questionnaireContext.investorFund.generalInfo.capitalCommitment === '0' ||
					questionnaireContext.investorFund.generalInfo.capitalCommitment === '$0.00' ||
          questionnaireContext.investorFund.generalInfo.capitalCommitment === '€0.00';

				const isValidCapitalCommitment2 =
					questionnaireContext.investorFund.generalInfo.capitalCommitmentFund2 === '0' ||
					questionnaireContext.investorFund.generalInfo.capitalCommitmentFund2 === '$0.00' ||
          questionnaireContext.investorFund.generalInfo.capitalCommitment === '€0.00';;

				const isValidCapitalCommitment3 =
					questionnaireContext.investorFund.generalInfo.capitalCommitmentFund3 === '0' ||
					questionnaireContext.investorFund.generalInfo.capitalCommitmentFund3 === '$0.00' ||
          questionnaireContext.investorFund.generalInfo.capitalCommitment === '€0.00';;

				if (key === 'capitalCommitment') {
					if (
						(!questionnaireContext.investorFund.generalInfo.capitalCommitment &&
							!questionnaireContext.investorFund.generalInfo.capitalCommitmentFund2) ||
						(isValidCapitalCommitment && isValidCapitalCommitment2 && isValidCapitalCommitment3)
					) {
						if (isAdminUserRequired(key)) {
							sigEnabled = false;
							tempMissingParts.part1 = part1Name;
							tempMissingGeneralInfo.push(generalInfoFields[key]);
						}
					}
				}
			} 

      });

    if (isNaturalPerson) {
      if (
        !questionnaireContext.investorFund.generalInfo.country ||
        questionnaireContext.investorFund.generalInfo.country === ''
      ) {
        tempMissingParts.part1 = part1Name;
        tempMissingGeneralInfo.push('Country');
      }
      if (
        !questionnaireContext.investorFund.generalInfo
          .principalPlaceOfResidencePrimaryContact ||
        questionnaireContext.investorFund.generalInfo
          .principalPlaceOfResidencePrimaryContact === ''
      ) {
        if (!questionnaireContext.investorFund.generalInfo.countryPlace) {
          tempMissingParts.part1 = part1Name;
          tempMissingGeneralInfo.push('Place of Residence');
        }

        if (questionnaireContext.investorFund.generalInfo.countryPlace === 'US' || questionnaireContext.investorFund.generalInfo.countryPlace === 'CA') {
          if (!questionnaireContext.investorFund.generalInfo.principalPlaceOfResidencePrimaryContact && isAdminUserRequired('state')) {
            tempMissingParts.part1 = part1Name;
            tempMissingGeneralInfo.push('State / Province / Territory');
          }
        }
    }
    } else if (
      !questionnaireContext.investorFund.generalInfo
        .principalPlaceOfBusinessPrimaryContact ||
      questionnaireContext.investorFund.generalInfo
        .principalPlaceOfBusinessPrimaryContact === ''
    ) {
      if (!questionnaireContext.investorFund.generalInfo.countryPlace && isAdminUserRequired('country')) {
        tempMissingParts.part1 = part1Name;
        tempMissingGeneralInfo.push('Place of Business - Country');
      }

      if (questionnaireContext.investorFund.generalInfo.countryPlace === 'US' || questionnaireContext.investorFund.generalInfo.countryPlace === 'CA') {
        if (!questionnaireContext.investorFund.generalInfo.principalPlaceOfResidencePrimaryContact && isAdminUserRequired('State')) {
          tempMissingParts.part1 = part1Name;
          tempMissingGeneralInfo.push('State / Province / Territory');
        }
      }
    }

    if ((currentIQVersion.includes(CaymanV3) ||
         currentIQVersion.includes(CaymanV4) ||
         currentIQVersion.includes(LimitedPartnerV12) ||
         currentIQVersion.includes(LPABBRV5) ||
         currentIQVersion.includes(AccelEntrepreneursV2) ||
         currentIQVersion.includes(AccelMainV2) ||
         currentIQVersion.includes(AudereV3) ||
         currentIQVersion.includes(BITKRAFTV3) ||
         currentIQVersion.includes(ElephantPartnerV3) ||
         currentIQVersion.includes(FireworkV3) ||
         currentIQVersion.includes(ForesiteLabsV5) ||
         currentIQVersion.includes(ForesiteV8) ||
         currentIQVersion.includes(FrazierV2) ||
         currentIQVersion.includes(HCMV5) ||
         currentIQVersion.includes(IOLARV3) ||
         currentIQVersion.includes(InitializedV8) ||
         currentIQVersion.includes(LPABBRV5) ||
         currentIQVersion.includes(LPMULTIFUNDV4) ||
         currentIQVersion.includes(LimitedLiabilityCompanyV10) ||
         currentIQVersion.includes(LimitedPartnerV12) ||
         currentIQVersion.includes(LimitedPartnershipMFV4) ||
         currentIQVersion.includes(NewLimitedPartnerV3) ||
         currentIQVersion.includes(OSAGEV3) ||
         currentIQVersion.includes(PrometheanV3) ||
         currentIQVersion.includes(QuestaCapitalV8) ||
         currentIQVersion.includes(SROneV2) ||
         currentIQVersion.includes(TcgCrossOverV3)) && 
         isAdminUserRequired('State') &&
         !isNaturalPerson && (questionnaireContext.fund && questionnaireContext.fund.version &&
          questionnaireContext.fund.version === FUND_VERSION.v4)
        ) {
      if (questionnaireContext.investorFund.generalInfo && 
        !questionnaireContext.investorFund.generalInfo.jurisdictionOrganization || 
        questionnaireContext.investorFund.generalInfo.jurisdictionOrganization === '') {
        tempMissingParts.part1 = part1Name;
          tempMissingGeneralInfo.push('Jurisdiction of Organization');
      }

       if (questionnaireContext.investorFund.generalInfo.jurisdictionOrganization === 'US' || 
           questionnaireContext.investorFund.generalInfo.jurisdictionOrganization === 'CA') {
        if (!questionnaireContext.investorFund.generalInfo.jurisdictionOrganizationPrimaryContact) {
          tempMissingParts.part1 = part1Name;
          tempMissingGeneralInfo.push('Jurisdiction - State / Province / Territory');
        }
      }
    }
    
    if (
      (!questionnaireContext.investorFund.generalInfo.otherValue ||
        questionnaireContext.investorFund.generalInfo.otherValue === '') &&
      (questionnaireContext.investorFund.generalInfo.types.includes('Other - Entity') ||
        questionnaireContext.investorFund.generalInfo.types.includes('Other - Individual'))
    ) {
      tempMissingParts.part1 = part1Name;
      tempMissingGeneralInfo.push('Please enter organization type name');
      sigEnabled = false;
    }

    Object.keys(requiredQuestions).forEach((key) => {
      let found = false;
      questionnaireContext.investorFund.questionnaire.questions.forEach(
        (question) => {
          if (question.descriptionIdentifier === key) {
            found = true;
            if (
              question.descriptionIdentifier === iqID.id_wiring_bank_status_1 &&
              question.answerIdentifier === `${iqID.id_wiring_bank_status_1}_no`
            ) {
              const wiringBankLocation = _.find(
                questionnaireContext.investorFund.questionnaire.questions,
                (quest) =>
                  quest.descriptionIdentifier ===
                  iqID.id_wiring_bank_status_1_opt
              );
              if (
                (!wiringBankLocation || wiringBankLocation.answer === '') &&
                !tempMissingQuestions.includes(
                  'Wiring Bank Status (Wiring bank location)'
                )
              ) {
                tempMissingQuestions.push(
                  'Wiring Bank Status (Wiring bank location)'
                );
              }
            }
          }
        }
      );
      if (!found) {
        tempMissingQuestions.push(requiredQuestions[key]);
      }
    });
    if (currentIQVersion && currentIQVersion.includes(HCMV1) || currentIQVersion.includes(HCMV2) || currentIQVersion.includes(HCMV3)) {
      let found = false;
      let foundTrigger = true;
      Object.keys(requireHCMQuestions).forEach((key) => {
        questionnaireContext.investorFund.questionnaire.questions.forEach(
          (question) => {
            //TODO: need to check for trigger
            if (
              !key.includes('_trigger') &&
              question.descriptionIdentifier === key &&
              question.answer !== 'No' &&
              !found
            ) {
              found = true;

              // these questions have triggers , they need to become configurable
              if (
                question.descriptionIdentifier ===
                'id_investor_status_7_hcm_other'
              ) {
                foundTrigger = false;
                const lookup =
                  questionnaireContext.investorFund.questionnaire.questions.find(
                    (q) =>
                      q.descriptionIdentifier ===
                      'id_investor_status_7_hcm_other_trigger'
                  );
                if (lookup) foundTrigger = true;
              }
              if (
                question.descriptionIdentifier === 'id_investor_status_7_hcm_m'
              ) {
                foundTrigger = false;
                const lookup =
                  questionnaireContext.investorFund.questionnaire.questions.find(
                    (q) =>
                      q.descriptionIdentifier ===
                      'id_investor_status_7_hcm_m_trigger'
                  );
                if (lookup) foundTrigger = true;
              }
            }
            //
          }
        );
      });
      if (!found) {
        tempMissingQuestions.push(
          'Categories of Beneficial Ownership for Advisers Act Reporting'
        );
      }
      if (!foundTrigger) {
        tempMissingQuestions.push(
          'Categories of Beneficial Ownership for Advisers Act Reporting'
        );
      }
    }

    if (isAdminUserRequired('bitkraft') &&  
       includesFormPFQuestion && currentIQVersion &&
      (currentIQVersion.includes(BITKRAFTV1) ||
       currentIQVersion.includes(BITKRAFTV2) ||
       currentIQVersion.includes(BITKRAFTV3) ||
       currentIQVersion.includes(ElephantPartnerV1) ||
       currentIQVersion.includes(ElephantPartnerV2) ||
       currentIQVersion.includes(ElephantPartnerV3) ||
       currentIQVersion.includes(AccelMainV1) ||
       currentIQVersion.includes(AccelMainV2) ||
       currentIQVersion.includes(AccelEntrepreneursV1) ||
       currentIQVersion.includes(AccelEntrepreneursV2)
      )) {
      let found = false;

      requireTCGQuestions.forEach((key) => {
        questionnaireContext.investorFund.questionnaire.questions.forEach(
          (question) => {
            if (
              question.descriptionIdentifier === key &&
              question.answer !== 'No' &&
              !found
            ) {
            switch(question.descriptionIdentifier) {
                case 'id_investor_status_7_tcg_o':
                   found = question.description !== "";
                   break;
                case 'id_investor_status_7_tcg_m':
                   found = question.description !== "";
                   break;
                default:
                   found = true
                   break;
              }
            }
          }
        );
      });
      if (!found) {
        tempMissingQuestions.push(
          'Beneficial Ownership for Advisers'
        );
      }
    }

    if (currentIQVersion && (currentIQVersion.includes(SROneV1) || currentIQVersion.includes(SROneV2))
       && isAdminUserRequired('SrOne')) {
        if (isNaturalPerson) {
          let found = false;

          const toRequiredA = [
						iqID.id_sr_one_part_a_1,
						iqID.id_sr_one_part_a_2,
						
					];

          toRequiredA.forEach((key) => {
          questionnaireContext.investorFund.questionnaire.questions.forEach(
            (question) => {
              if (
                question.descriptionIdentifier === key &&
                question.answer !== 'No' &&
                !found
              ) {
                found = true;
              }
            }
          );
        });

          const toRequiredB = [
            iqID.id_sr_one_part_b_1,
            iqID.id_sr_one_part_b_2,
          ];

        let found2 = false;

        toRequiredB.forEach((key) => {
          questionnaireContext.investorFund.questionnaire.questions.forEach(
            (question) => {
              if (
                question.descriptionIdentifier === key &&
                question.answer !== 'No' &&
                !found2
              ) {
                found2 = true;
              }
                if (key === iqID.id_sr_one_part_b_1 && question.answer === 'Yes') {
                  const optionA = _.find(questionnaireContext.investorFund.questionnaire.questions, 
                        (quest) => quest.descriptionIdentifier === iqID.id_sr_one_part_b_1_a)  || { answer: 'No' };
                  const optionB = _.find(questionnaireContext.investorFund.questionnaire.questions, 
                        (quest) => quest.descriptionIdentifier === iqID.id_sr_one_part_b_1_b)  || { answer: 'No' };
                  const optionC = _.find(questionnaireContext.investorFund.questionnaire.questions, 
                        (quest) => quest.descriptionIdentifier === iqID.id_sr_one_part_b_1_c) || { answer: 'No' };
                  const optionNote = _.find(questionnaireContext.investorFund.questionnaire.questions, 
                        (quest) => quest.descriptionIdentifier === iqID.id_sr_one_part_b_1_text_note);
                 
                if (optionA.answer === 'No' &&
                    optionB.answer === 'No' && 
                    optionC.answer === 'No') {
                    found2 = false;
                } 
                if (!optionNote || optionNote && optionNote.answer === '') {
                  found2 = false;
                }
              }
          });
        });
        if (!found || !found2) {
          tempMissingQuestions.push(
            'New Issues Questionnaire'
          );
        }

      } else if (!isNaturalPerson) {
        let found = false;

        const toRequiredA = [
          'id_sr_one_part_a_3',
          'id_sr_one_part_a_or',
        ];
        const exhibitCAIds = [
            iqID.id_sr_one_a_exhibit_c_1,
            iqID.id_sr_one_a_exhibit_c_2,
            iqID.id_sr_one_a_exhibit_c_3,
            iqID.id_sr_one_a_exhibit_c_4,
            iqID.id_sr_one_a_exhibit_c_5,
            iqID.id_sr_one_a_exhibit_c_6,
            iqID.id_sr_one_a_exhibit_c_7,
            iqID.id_sr_one_a_exhibit_c_8,
            iqID.id_sr_one_a_exhibit_c_9,
            iqID.id_sr_one_a_exhibit_c_10,
            iqID.id_sr_one_a_exhibit_c_11,
          ];

          toRequiredA.forEach((key) => {
          questionnaireContext.investorFund.questionnaire.questions.forEach(
            (question) => {
              if (
                question.descriptionIdentifier === key &&
                question.answer !== 'No' &&
                question.answer !== '' &&
                !found
              ) {
                found = true;
              }
               if (key === iqID.id_sr_one_part_a_3 && question.answer === 'Yes') {

                const optionA = _.find(questionnaireContext.investorFund.questionnaire.questions, 
                  (quest) => quest.descriptionIdentifier === iqID.id_sr_one_part_a_3_a)  || { answer: 'No' };
                const optionB = _.find(questionnaireContext.investorFund.questionnaire.questions, 
                  (quest) => quest.descriptionIdentifier === iqID.id_sr_one_part_a_3_b)  || { answer: 'No' };
                const optionC = _.find(questionnaireContext.investorFund.questionnaire.questions, 
                  (quest) => quest.descriptionIdentifier === iqID.id_sr_one_part_a_3_c) || { answer: 'No' };;
                 
                if (optionA.answer === 'No' &&
                    optionB.answer === 'No' && 
                    optionC.answer === 'No') {
                    found = false;
                }
                if (optionB.answer === 'Yes') {
                    const exhibitAQuestion = _.find(questionnaireContext.investorFund.questionnaire.questions, 
                      (quest) => exhibitCAIds.includes(quest.descriptionIdentifier) && quest.answer === 'Yes');
                    if (!exhibitAQuestion) {
                      found = false;
                    }
                }
              }
            }
          );
        });


         const toRequiredB = [
          'id_sr_one_part_b_3',
          'id_sr_one_part_b_or',
        ];

          const exhibitCBIds = [
            iqID.id_sr_one_b_exhibit_c_1,
            iqID.id_sr_one_b_exhibit_c_2,
            iqID.id_sr_one_b_exhibit_c_3,
            iqID.id_sr_one_b_exhibit_c_4,
            iqID.id_sr_one_b_exhibit_c_5,
            iqID.id_sr_one_b_exhibit_c_6,
            iqID.id_sr_one_b_exhibit_c_7,
            iqID.id_sr_one_b_exhibit_c_8,
            iqID.id_sr_one_b_exhibit_c_9,
            iqID.id_sr_one_b_exhibit_c_10,
            iqID. id_sr_one_b_exhibit_c_11,
          ];

        let found2 = false;
        toRequiredB.forEach((key) => {
          questionnaireContext.investorFund.questionnaire.questions.forEach(
            (question) => {
              if (
                question.descriptionIdentifier === key &&
                question.answer !== 'No' &&
                question.answer !== '' &&
                !found2
              ) {
                found2 = true;
              }
               if (key === iqID.id_sr_one_part_b_3 && question.answer === 'Yes') {
                  
                const optionA = _.find(questionnaireContext.investorFund.questionnaire.questions, 
                    (quest) => quest.descriptionIdentifier === iqID.id_sr_one_part_b_3_a) || { answer: 'No' };
                  const optionB = _.find(questionnaireContext.investorFund.questionnaire.questions, 
                    (quest) => quest.descriptionIdentifier === iqID.id_sr_one_part_b_3_b)  || { answer: 'No' };
                  const optionC = _.find(questionnaireContext.investorFund.questionnaire.questions, 
                    (quest) => quest.descriptionIdentifier === iqID.id_sr_one_part_b_3_c)  || { answer: 'No' };
                  
                   if (optionA.answer === 'No' &&
                       optionB.answer === 'No' && 
                       optionC.answer === 'No') {
                    found2 = false;
                    }
                  
                  if (optionB.answer === 'Yes') {
                    const exhibitBQuestion = _.find(questionnaireContext.investorFund.questionnaire.questions, 
                      (quest) => exhibitCBIds.includes(quest.descriptionIdentifier) && quest.answer === 'Yes');
                    if (!exhibitBQuestion) {
                      found2 = false;
                    }
                }
               }
            }
          );
        });
        if (!found2 || !found) {
          tempMissingQuestions.push('New Issues Questionnaire');
        }
      }
    }

    if (currentIQVersion && (currentIQVersion.includes(AccelMainV1) || currentIQVersion.includes(AccelMainV2)) && isAdminUserRequired('AccelMain')) {

      const identifierCompanyBasis = [
        'id_griw7_2CompanyBasis_a',
        'id_griw7_2CompanyBasis_b',
        'id_griw7_2CompanyBasis_c',
      ]

      const erisaB = _.find(questionnaireContext.investorFund.questionnaire.questions, 
        (quest) => quest.descriptionIdentifier === iqID.id_special_entities_1_b);
      if (
        !isNaturalPerson &&
        !erisaB &&
        !tempMissingQuestions.includes('ERISA Partner (b)')
      ) {
        tempMissingQuestions.push('ERISA Partner (b)');
      }

      const potentialLookthroughOptB = _.find(
        questionnaireContext.investorFund.questionnaire.questions,
        (quest) =>
          quest.descriptionIdentifier ===
          iqID.id_special_entities_2_e_opt
      );

      const flowThroughEntityA = _.find(
      questionnaireContext.investorFund.questionnaire.questions,
      (quest) => quest.descriptionIdentifier === iqID.id_special_entities_2_a
    );

      if (
        (!potentialLookthroughOptB ||
        potentialLookthroughOptB.answer === '') &&
        flowThroughEntityA && flowThroughEntityA.answer === 'Yes' 
      ) {
        tempMissingQuestions.push('Potential Lookthrough Entity (b)');
      }

      const retirementPlan = _.find(
      questionnaireContext.investorFund.questionnaire.questions,
      (quest) => quest.descriptionIdentifier === iqID.id_retirementPlan
    );
    if (!isNaturalPerson && !retirementPlan || retirementPlan && retirementPlan.answer === '') {
     tempMissingQuestions.push('Retirement Plan');
    }
     
    const idGirw7i = questionnaireContext.investorFund.questionnaire.questions.find(question => question.descriptionIdentifier === 'id_girw_7_i' && question.answer !== 'No');
    const idGirw72d = questionnaireContext.investorFund.questionnaire.questions.find(question => question.descriptionIdentifier === 'id_girw_7_2_d' && question.answer !== 'No');
    const idGriw72f = questionnaireContext.investorFund.questionnaire.questions.find(question => question.descriptionIdentifier === 'id_girw_7_2_f' && question.answer !== 'No');

      if (idGirw7i  && idGirw7i.description === '' ) {
        tempMissingQuestions.push('Representations and Covenants Nº 7(9)');
      }
      
      if (idGriw72f && idGriw72f.description === '') {
        tempMissingQuestions.push('Representations and Covenants Nº 7(15)');
      } 
      
      if (idGirw72d) {
        const companyBasis = questionnaireContext.investorFund.questionnaire.questions.some(question => identifierCompanyBasis.includes(question.descriptionIdentifier) && question.answer !== 'No')
        if (!companyBasis) {
         tempMissingQuestions.push('Representations and Covenants Nº 7(13)');
        }
      } 

      const foreignPartner = _.find(
      questionnaireContext.investorFund.questionnaire.questions,
      (quest) => quest.descriptionIdentifier === iqID.id_special_entities_10
    );
    if (!foreignPartner || !foreignPartner.answer) {
      requiredQuestions.id_special_entities_10 = 'Foreign Partner'
    }

    const clientRepresentations2 = _.find(
      questionnaireContext.investorFund.questionnaire.questions,
      (quest) => quest.descriptionIdentifier === iqID.id_client_representations_2
    );
    
    if (clientRepresentations2 && clientRepresentations2.answer === 'Yes') {
      let foundClient2 = false;
        questionnaireContext.investorFund.questionnaire.questions.forEach(
          (question) => {
            if (
              question &&
              (question.descriptionIdentifier === iqID.id_client_representations_2_a ||
              question.descriptionIdentifier === iqID.id_client_representations_2_b) &&
              question.answer !== 'No'
            ) {
              foundClient2 = true;
            } 
          });
      if (!foundClient2) {
        tempMissingQuestions.push('Qualified Client Representations (2)');
      }
    }

  }

  if (currentIQVersion && includesFormPFQuestion &&
    (currentIQVersion.includes(TcgCrossOverV1) || 
     currentIQVersion.includes(TcgCrossOverV2) || 
     currentIQVersion.includes(TcgCrossOverV3)) && isAdminUserRequired('TcgCrossOver')) {
      let found = false;

      requireTCGQuestions.forEach((key) => {
        questionnaireContext.investorFund.questionnaire.questions.forEach(
          (question) => {
            if (
              question.descriptionIdentifier === key &&
              question.answer !== 'No' &&
              !found
            ) {
            switch(question.descriptionIdentifier) {
                case 'id_investor_status_7_tcg_o':
                   found = question.description !== "";
                   break;
                case 'id_investor_status_7_tcg_m':
                   found = question.description !== "";
                   break;
                default:
                   found = true
                   break;
              }
            }
          }
        );
      });
      if (!found) {
        tempMissingQuestions.push(
          'Beneficial Ownership for Advisers'
        );
      }
      
      let found1 = false;
      requiredRestrictedPerson.forEach((key) => {
        questionnaireContext.investorFund.questionnaire.questions.forEach(
          (question) => {
            if (
              question.descriptionIdentifier === key &&
              question.answer !== 'No' &&
              !found1
            ) {   
                switch(question.descriptionIdentifier) {
                case 'id_restricted_person_status_k':
                   found1 = question.description !== "";
                   break;
                default:
                   found1 = true
                   break;
              }
            }
            
          }
        );
      });
      if (!found1) {
        tempMissingQuestions.push(
          'Determination of Restricted Person Status'
        );
      }
      let found2 = false;
      requiredCoveredInvestorStatus.forEach((key) => {
        
        questionnaireContext.investorFund.questionnaire.questions.forEach(
          (question) => {
            if (
              question.descriptionIdentifier === key &&
              question.answer !== 'No' &&
              !found2
            ) {
              switch(question.descriptionIdentifier) {
                case 'id_covered_investor_status_a':
                   found2 = question.description !== "";
                   break;
                case 'id_covered_investor_status_b':
                   found2 = question.description !== "";
                   break;
                default:
                   found2 = true;
                   break;
              }
            }
          }
        );
      });
      if (!found2) {
        tempMissingQuestions.push(
          'Determination of Covered Investor Status'
        );
      }

        const questionData = getInvestorFundQuestions(questionnaireContext.investorFund.questionnaire.questions);

          if (!isNaturalPerson) {
          let found3 = false;
          requiredExemptedEntityStatus.forEach((key) => {
          
            questionnaireContext.investorFund.questionnaire.questions.forEach(
              (question) => {
                if (
                  question.descriptionIdentifier === key &&
                  question.answer !== 'No' &&
                  !found3
                ) {
                  found3 = true;
                }
              }
            );
          });
          if (questionData &&
            questionData.coveredInvestorStatusor && questionData.coveredInvestorStatusor.answer === 'Yes' &&
              questionData.restrictedPersonStatusor && questionData.restrictedPersonStatusor.answer === 'Yes' ){
                found3 = true;
          }
          if (!found3) {
            tempMissingQuestions.push(
              'Determination of Exempted Entity Status'
            );
              
          }
        }
    }

    const institutionalAccountStatus = _.find(
      questionnaireContext.investorFund.questionnaire.questions,
      (quest) => quest.descriptionIdentifier === iqID.id_investor_status_7
    );
    if (currentIQVersion && isAdminUserRequired('QuestaCapital')) {
      if (
        currentIQVersion.includes(QuestaCapitalV1) ||
        currentIQVersion.includes(QuestaCapitalV2) ||
        currentIQVersion.includes(QuestaCapitalV3) ||
        currentIQVersion.includes(QuestaCapitalV4) ||
        currentIQVersion.includes(QuestaCapitalV5) ||
        currentIQVersion.includes(QuestaCapitalV6) ||
        currentIQVersion.includes(QuestaCapitalV7) ||
        currentIQVersion.includes(QuestaCapitalV8)
      ) {
        if (!institutionalAccountStatus) {
          tempMissingQuestions.push('Institutional Account Status');
        }
      }
    }

    if (isNaturalPerson) {
      let foundInvestor = false;
      let foundPurchaser = false;
      let foundClient = false;
      let foundForeign = false;
      const purchaserOr = _.find(
        questionnaireContext.investorFund.questionnaire.questions,
        (quest) =>
          quest.descriptionIdentifier === iqID.id_purchase_representations_or
      );
      requiredNaturalPersonCheckBoxQuestions.investor.questions.forEach(
        (questionKey) => {
          questionnaireContext.investorFund.questionnaire.questions.forEach(
            (question) => {
              if (
                question.descriptionIdentifier === questionKey &&
                question.answer === 'Yes'
              ) {
                foundInvestor = true;
              }
            }
          );
        }
      );
      requiredNaturalPersonCheckBoxQuestions.purchaser.questions.forEach(
        (questionKey) => {
          questionnaireContext.investorFund.questionnaire.questions.forEach(
            (question) => {
              if (
                question.descriptionIdentifier === questionKey &&
                question.answer === 'Yes'
              ) {
                foundPurchaser = true;
              }
            }
          );
        }
      );
      if (requiredNaturalPersonCheckBoxQuestions.client &&
      !foundPurchaser || (purchaserOr && purchaserOr.answer === 'Yes') 
      && !currentIQVersion.includes(AccelV1) 
      && !currentIQVersion.includes(AccelV2) 
      && !currentIQVersion.includes(AccelV3)
      && !currentIQVersion.includes(AccelV4)
      && !currentIQVersion.includes(AccelEntrepreneursV1)
      && !currentIQVersion.includes(AccelEntrepreneursV2)) {
        requiredNaturalPersonCheckBoxQuestions.client.questions.forEach(
          (questionKey) => {
            questionnaireContext.investorFund.questionnaire.questions.forEach(
              (question) => {
                if (
                  question.descriptionIdentifier === questionKey &&
                  question.answer === 'Yes'
                ) {
                  foundClient = true;
                }
              }
            );
          }
        );
      } else {
        foundClient = true;
      }
      if (requiredNaturalPersonCheckBoxQuestions.foreign) {
        requiredNaturalPersonCheckBoxQuestions.foreign.questions.forEach(
          (questionKey) => {
            questionnaireContext.investorFund.questionnaire.questions.forEach(
              (question) => {
                if (
                  question.descriptionIdentifier === questionKey &&
                  question.answer === 'Yes'
                ) {
                  foundForeign = true;
                }
              }
            );
          }
        );
      }

      if (!foundInvestor) {
        tempMissingQuestions.push(
          requiredNaturalPersonCheckBoxQuestions.investor.title
        );
      }
      if (!foundPurchaser) {
        tempMissingQuestions.push(
          requiredNaturalPersonCheckBoxQuestions.purchaser.title
        );
      }
      if (!foundClient) {
        tempMissingQuestions.push(
          requiredNaturalPersonCheckBoxQuestions.client.title
        );
      }
      if (currentIQVersion) {
        if (!foundForeign && hasForeign) {
          tempMissingQuestions.push(
            requiredNaturalPersonCheckBoxQuestions.foreign.title
          );
        }
      }
    }
    if (!isNaturalPerson) {
      Object.keys(requiredNonNaturalPersonQuestions).forEach((key) => {
        let found = false;
        questionnaireContext.investorFund.questionnaire.questions.forEach(
          (question) => {
            if (question.descriptionIdentifier === key && question.answerIdentifier && question.answerIdentifier !== '') {
              found = true;
              if (
                !currentIQVersion.includes(AccelV1) &&
                !currentIQVersion.includes(AccelV2) &&
                !currentIQVersion.includes(AccelV3) &&
                !currentIQVersion.includes(AccelV4) &&
                !currentIQVersion.includes(AccelMainV1) &&
                !currentIQVersion.includes(AccelMainV2) &&
                !currentIQVersion.includes(AccelEntrepreneursV1) &&
                !currentIQVersion.includes(AccelEntrepreneursV2) &&
                question.descriptionIdentifier ===
                  iqID.id_special_entities_1_a &&
                question.answerIdentifier ===
                  `${iqID.id_special_entities_1_a}_no`
              ) {
                const erisaB = _.find(
                  questionnaireContext.investorFund.questionnaire.questions,
                  (quest) =>
                    quest.descriptionIdentifier === iqID.id_special_entities_1_b
                );
                if (
                  !erisaB &&
                  !tempMissingQuestions.includes('ERISA Partner (b)')
                ) {
                  tempMissingQuestions.push('ERISA Partner (b)');
                }
              }
              if (
                (!currentIQVersion.includes(AccelMainV1) || !currentIQVersion.includes(AccelMainV2)) &&
                (question.descriptionIdentifier ===
                  iqID.id_special_entities_2_a &&
                  question.answerIdentifier ===
                    `${iqID.id_special_entities_2_a}_yes`) ||
                (question.descriptionIdentifier ===
                  iqID.id_special_entities_2_b &&
                  question.answerIdentifier ===
                    `${iqID.id_special_entities_2_b}_yes`) ||
                (question.descriptionIdentifier ===
                  iqID.id_special_entities_2_c &&
                  question.answerIdentifier ===
                    `${iqID.id_special_entities_2_c}_yes`) ||
                (question.descriptionIdentifier ===
                  iqID.id_special_entities_2_d &&
                  question.answerIdentifier ===
                    `${iqID.id_special_entities_2_d}_yes`)
              ) {
                const potentialLookthroughOptE = _.find(
                  questionnaireContext.investorFund.questionnaire.questions,
                  (quest) =>
                    quest.descriptionIdentifier ===
                    iqID.id_special_entities_2_e_opt
                );
                const specialEntities_2_c = _.find(
                  questionnaireContext.investorFund.questionnaire.questions,
                  (quest) =>
                    quest.descriptionIdentifier === iqID.id_special_entities_2_c
                );
                if (
                  (!potentialLookthroughOptE ||
                    potentialLookthroughOptE.answer === '') &&
                  !tempMissingQuestions.includes(
                    'Potential Lookthrough Entity (e)'
                  )
                ) {
                  tempMissingQuestions.push('Potential Lookthrough Entity (e)');
                }
              }
            }
          }
        );
        if (!found) {
          tempMissingQuestions.push(requiredNonNaturalPersonQuestions[key]);
        }
      });
      let foundInvestor = false;
      let foundPurchaser = false;
      let foundClient = false;
      let foundForeign = false;
      let foundForeign2 = false;
      const purchaserOr = _.find(
        questionnaireContext.investorFund.questionnaire.questions,
        (quest) =>
          quest.descriptionIdentifier === iqID.id_purchase_representations_or
      );
      requiredNonNaturalPersonCheckBoxQuestions.investor.questions.forEach(
        (questionKey) => {
          questionnaireContext.investorFund.questionnaire.questions.forEach(
            (question) => {
              if (
                question.descriptionIdentifier === questionKey &&
                question.answer === 'Yes'
              ) {
                foundInvestor = true;
              }
            }
          );
        }
      );
      requiredNonNaturalPersonCheckBoxQuestions.purchaser.questions.forEach(
        (questionKey) => {
          questionnaireContext.investorFund.questionnaire.questions.forEach(
            (question) => {
              if (
                question.descriptionIdentifier === questionKey &&
                question.answer === 'Yes'
              ) {
                foundPurchaser = true;
              }
            }
          );
        }
      );
      if (!foundPurchaser || (purchaserOr && purchaserOr.answer === 'Yes') 
      && !currentIQVersion.includes(AccelV1) 
      && !currentIQVersion.includes(AccelV2) 
      && !currentIQVersion.includes(AccelV3) 
      && !currentIQVersion.includes(AccelV4) 
      && !currentIQVersion.includes(AccelEntrepreneursV1)
      && !currentIQVersion.includes(AccelEntrepreneursV2)) {
        requiredNonNaturalPersonCheckBoxQuestions.client.questions.forEach(
          (questionKey) => {
            questionnaireContext.investorFund.questionnaire.questions.forEach(
              (question) => {
                if (
                  question.descriptionIdentifier === questionKey &&
                  question.answer === 'Yes'
                ) {
                  foundClient = true;
                }
              }
            );
          }
        );
      } else {
        foundClient = true;
      }
      if (requiredNonNaturalPersonCheckBoxQuestions.foreign) {
        requiredNonNaturalPersonCheckBoxQuestions.foreign.questions.forEach(
          (questionKey) => {
            questionnaireContext.investorFund.questionnaire.questions.forEach(
              (question) => {
                if (
                  question.descriptionIdentifier === questionKey &&
                  question.answer === 'Yes'
                ) {
                  foundForeign = true;
                }
              }
            );
          }
        );
      }
      if (requiredNonNaturalPersonCheckBoxQuestions.foreign2) {
        requiredNonNaturalPersonCheckBoxQuestions.foreign2.questions.forEach(
          (questionKey) => {
            questionnaireContext.investorFund.questionnaire.questions.forEach(
              (question) => {
                if (
                  question.descriptionIdentifier === questionKey &&
                  (question.answer === 'Yes' || question.answer === 'No')
                ) {
                  foundForeign2 = true;
                }
              }
            );
          }
        );
      }
      if (!foundInvestor) {
        tempMissingQuestions.push(
          requiredNonNaturalPersonCheckBoxQuestions.investor.title
        );
      }
      if (!foundPurchaser) {
        tempMissingQuestions.push(
          requiredNonNaturalPersonCheckBoxQuestions.purchaser.title
        );
      }
      if (!foundClient && (!currentIQVersion.includes(AccelV1) 
        && !currentIQVersion.includes(AccelV2) 
        && !currentIQVersion.includes(AccelV3)
        && !currentIQVersion.includes(AccelV4)
        && !currentIQVersion.includes(AccelEntrepreneursV1)
        && !currentIQVersion.includes(AccelEntrepreneursV2))) {
        tempMissingQuestions.push(
          requiredNonNaturalPersonCheckBoxQuestions.client.title
        );
      }
      if (currentIQVersion) {
        if (!foundForeign && hasForeign) {
          tempMissingQuestions.push(
            requiredNonNaturalPersonCheckBoxQuestions.foreign.title
          );
        }
        if (!foundForeign2 && hasForeign2) {
          tempMissingQuestions.push(
            requiredNonNaturalPersonCheckBoxQuestions.foreign2.title
          );
        }
      }
    }
    const foiaE = _.find(
      questionnaireContext.investorFund.questionnaire.questions,
      (quest) => quest.descriptionIdentifier === iqID.id_investor_status_5_e
    );
    if (
      foiaE &&
      foiaE.answerIdentifier === `${iqID.id_investor_status_5_e}_yes`
    ) {
      const foiaF = _.find(
        questionnaireContext.investorFund.questionnaire.questions,
        (quest) => quest.descriptionIdentifier === iqID.id_investor_status_5_f
      );
      if (!foiaF) {
        tempMissingQuestions.push('FOIA (f)');
      }
    }

    const parts = [];
    let missingFields = '';
    let missingQuestions = '';
    if (tempMissingGeneralInfo.length > 0) {
      tempMissingGeneralInfo.forEach((field) => {
        missingFields += ` - ${field}`;
      });
    }
    if (tempMissingParts.part1 && missingFields) {
      parts.push(`${tempMissingParts.part1} ${missingFields}`);
      sigEnabled = false;
    }
    if (tempMissingQuestions.length > 0 && isAdminUserRequired('part2')) {
      sigEnabled = false;
      tempMissingParts.part2 = `Part ${extraSectionPart2} - Questionnaire`;
      tempMissingQuestions.forEach((question) => {
        missingQuestions += ` - ${question}`;
      });
      parts.push(`${tempMissingParts.part2} ${missingQuestions}`);
      
    }

    // remove all tempMissingQuestions if it is a returning investor
    if (currentIQVersion && (
      currentIQVersion.includes(AccelEtpnReturningV1) || 
      currentIQVersion.includes(AccelEtpnReturningV2) ||
      currentIQVersion.includes(AccelEtpnReturningMultiV1))) {
      sigEnabled = true;
      parts.splice(0, parts.length);
    };

    props.isSignaturesEnabled(sigEnabled);
    props.setValidationRequiredParts(parts);
  } else {
    props.isSignaturesEnabled(false);
    props.setValidationRequiredParts(['questionnaire.']);
  }
  
  return new Promise((resolve, reject) => {
    const isSubmitted = props.questionnaireContext.investorFund.submitted;
    const investor = props.questionnaireContext.investorFund;
    const requiresSubmission = !isSubmitted && sigEnabled &&
      questionnaireContext.investorFund.status !== STATUS_TYPES.SUBMITTED.label;
    // do not show instructions if there are errors
    if (
      // !parts.length &&
      !sessionStorage.getItem('alreadyOpenInstructions')
    ) {
      props.showSignaturesInstructions(true);
      sessionStorage.setItem('alreadyOpenInstructions', true);
    }
    if (requiresSubmission && investor._id) {
			submitQuestionnaire(props)
				.then((res) => {
					let resultAfterSubmit = {};
					if (questionnaireContext.multiAccess) {
						resultAfterSubmit = res.data.limitedPartnerFund;
					} else {
						resultAfterSubmit = res.data.investorFund;
					}
					props.setInvestorFund(resultAfterSubmit);
					resolve();
				})
				.catch((err) => {
					console.error(
						`Error submitted questionnaire for ${
							questionnaireContext.multiAccess
								? `'Limited Partner Fund' ${questionnaireContext.investorFund._id}`
								: `'Investor Fund' ${questionnaireContext.investorFund._id}`
						}`
					);
				});
		} else {
			resolve();
		}
  });
};

export const saveGeneralInformation = (_payload, _props) => {
  _props.setSavingInfo(true);
  axios
    .post(
      `${API_URL()}/access/generalInfo`,
      _payload,
      SERVICE_CONFIG_QUESTIONNAIRE()
    )
    .then((res) => {
      sessionStorage.setItem(
        'generalInfo',
        JSON.stringify(res.data.investorFund.generalInfo)
      );
      _props.setInvestorFund(res.data.investorFund);
      _props.setSavingInfo(false);
    })
    .catch((err) => {
      _props.setSavingInfo(false);
      _props.logQuestionnaireError(err, _props.questionnaireContext);
    });
};
